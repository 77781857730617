<template>
  <OnboardingLayout :disabled="disabled">
    <div class="warning-wrapper" v-if="disabled">
      <div class="left">
        <img src="@/assets/images/warningWhite.svg" />
      </div>

      <div class="right">
        <h2>{{$t('view_only_mode')}}</h2>
        <span>
          {{$t('view_only_mode_description')}}
        </span>
      </div>
    </div>

    <OnboardQuestion :disabled="disabled" />
  </OnboardingLayout>
</template>

<script>
import OnboardingLayout from "@/components/layouts/OnboardingLayout.vue";
import OnboardQuestion from "@/components/OnboardQuestion.vue";

export default {
  name: 'OnboardingDetails',
  components: {OnboardQuestion, OnboardingLayout},
  data() {
    return {
      disabled: false,
    }
  },
  mounted() {
    const routeParams = this.$route.params;

    if (routeParams.id === "2") {
      this.disabled = true;
    }
  }
}
</script>

<style scoped>
.warning-wrapper {
  background-color: #F09460;
  border: 1px solid #df8958;
  padding: 15px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
}

.warning-wrapper .left {
  margin-right: 15px;
}

.warning-wrapper .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.warning-wrapper .left img {
  width: 72px;
  height: 72px;
}

.warning-wrapper h2 {
  font-size: 16px;
  margin: 0;
  font-family: euclid_medium, sans-serif;
  color: #fff;
}

.warning-wrapper span {
  font-size: 12px;
  color: #EAECF0;
}
</style>
