<template>
  <div
      class="drop-wrapper"
      :class="{'active': dragActive}"
      @dragenter.prevent="setActive"
      @dragover.prevent="setActive"
      @dragleave.prevent="setInactive"
      @drop.prevent="onDrop"
      @click="selectFile"
  >
    <input type="file" :id="fileInputId" style="display: none" @change="onFileInputChange" accept="image/*">
    <img v-if="file === null" class="upload-image-placeholder" src="../assets/images/upload-image.svg" />
    <img v-else class="preview-image" :src="imagePreview" :alt="$t('file_preview')" />
  </div>
</template>

<script>
export default {
  name: 'IconDrop',
  data() {
    return {
      dragActive: false,
      file: null,
      imagePreview: null,
      fileInputId: null,
    }
  },
  methods: {
    setActive() {
      this.dragActive = true;
    },
    setInactive() {
      this.dragActive = false;
    },
    onDrop(e) {
      this.setInactive();
      const files = e.dataTransfer.files;
      this.handleFileSelection(files);
    },
    onFileInputChange(e) {
      const files = e.target.files;
      this.handleFileSelection(files);
    },
    selectFile() {
      document.getElementById(this.fileInputId).click();
    },
    handleFileSelection(files) {
      if (files.length > 0) {
        this.file = files[0];

        const reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
        };
        reader.readAsDataURL(files[0]);

        this.onFileSelected(files[0]);
      }
    },
  },
  created() {
    this.fileInputId = this.randomFileInputId;
  },
  computed: {
    randomFileInputId() {
      return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
  }
}
</script>

<style scoped>
.drop-wrapper {
  border: 1px solid #F5F5F5;
  border-radius: 8px;
  padding: 15px 15px;
  width: 52px;
  font-family: 'euclid_regular', sans-serif;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-image, .upload-image-placeholder {
  width: 36px;
  height: 36px;
  border-radius: 8px;
}

.active {
  border: 1px solid #4A68F8;
  background: #EBEDFB;
}
</style>
