<template>
  <div class="match-container no-text-select" ref="container">
    <div class="input-col">
      <div class="input-row" v-for="inputData in inputs" :key="inputData.id">
        <div class="input-wrapper">
          <div
              class="dot right"
              :style="{ backgroundColor: inputData.color || '#00D68F' }"
              :data-id="inputData.id"
              @mousedown="startDrag($event, inputData.id, 'right')"
          />
          <input
              v-if="!leftPartImage"
              class="form-input w-100"
              :placeholder="$t('enter_value')"
              v-model="inputData.leftValue"
          />
          <FileDrop
              v-else
              :image-editor-enabled="false"
              :on-file-selected="(file) => onFileSelected(file, inputData.id, 'left')"
          />
        </div>

        <div class="input-spacer" />

        <div class="input-wrapper">
          <div
              class="dot left"
              :data-id="inputData.id"
              @mousedown="startDrag($event, inputData.id, 'left')"
          />
          <input
              v-if="!rightPartImage"
              class="form-input w-100"
              :placeholder="$t('enter_value')"
              v-model="inputData.rightValue"
          />
          <FileDrop
              v-else
              :image-editor-enabled="false"
              :on-file-selected="(file) => onFileSelected(file, inputData.id, 'right')"
          />
        </div>


        <div class="remove-wrapper no-text-select">
          <img src="@/assets/images/trash.svg" @click="removeInput(inputData.id)" />
        </div>
      </div>
    </div>

    <div class="bottom-buttons-wrapper">
      <button @click="addInput">{{$t('add_new_input')}}</button>
      <button @click="resetConnections">{{$t('clear_connections')}}</button>
    </div>

    <!-- SVG for drawing the connection lines -->
    <svg class="connection-lines" ref="svgContainer">
      <line
          v-for="connection in connections"
          :key="connection.id"
          :x1="connection.x1"
          :y1="connection.y1"
          :x2="connection.x2"
          :y2="connection.y2"
          :stroke="connection.color ? connection.color : '#00D68F'"
          stroke-width="2"
      />
      <!-- Line being drawn while dragging -->
      <line
          v-if="dragging"
          :x1="currentConnection.x1"
          :y1="currentConnection.y1"
          :x2="currentConnection.x2"
          :y2="currentConnection.y2"
          stroke="#e0e0e0"
          stroke-width="2"
      />
    </svg>
  </div>
</template>

<script>
import {colorPalette} from "@/utils/utils";
import FileDrop from "@/components/FileDrop.vue";

export default {
  components: {FileDrop},
  data() {
    return {
      inputs: [
        { id: 1, leftValue: '', rightValue: ''},
      ],
      connections: [], // Store connections between dots
      dragging: false, // Is dragging in progress?
      currentConnection: null, // Data for the current line being drawn
    };
  },
  props: {
    inputValue: {
      type: Array,
      default: [],
    },
    connectionValue: {
      type: Array,
      default: [],
    },
    leftPartImage: {
      type: Boolean,
      default: true,
    },
    rightPartImage: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    inputValue: {
      handler(value) {
        if (value.length === 0) {
          return;
        }

        this.inputs = value;
      },
      immediate: true,
    },
    connectionValue: {
      handler(value) {
        if (value.length === 0) {
          return;
        }

        this.connections = value;
        this.recalculateLines();
      },
      immediate: true,
    },
    leftPartImage: {
      handler(value) {
        setTimeout(() => {
          this.recalculateLines();
        }, 100);
      },
      immediate: true,
      deep: true,
    },
    rightPartImage: {
      handler(value) {
        setTimeout(() => {
          this.recalculateLines();
        }, 100);
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.recalculateLines);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.recalculateLines);
  },
  methods: {
    // Starts the drag process
    startDrag(event, id, side) {
      const containerRect = this.$refs.container.getBoundingClientRect();

      this.dragging = true;

      // Get initial dot position relative to the container
      const rect = event.target.getBoundingClientRect();
      const x1 = rect.left + rect.width / 2 - containerRect.left;
      const y1 = rect.top + rect.height / 2 - containerRect.top;

      this.currentConnection = {
        id, // Store the ID of the input row
        x1,
        y1,
        x2: x1,
        y2: y1,
        side,
        targetId: null, // Target dot id (will be set on end drag)
        targetSide: null, // Target side ('left' or 'right')
      };

      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.endDrag);
    },
    // Track mouse movement and update the line endpoint
    onMouseMove(event) {
      if (!this.dragging) return;

      const containerRect = this.$refs.container.getBoundingClientRect();
      this.currentConnection.x2 = event.clientX - containerRect.left;
      this.currentConnection.y2 = event.clientY - containerRect.top;

      this.$forceUpdate(); // Trigger re-render
    },
    // End the dragging and complete the connection
    endDrag(event) {
      if (this.dragging) {
        const containerRect = this.$refs.container.getBoundingClientRect();
        const targetDot = document.elementFromPoint(event.clientX, event.clientY);

        // Snap to the center of the nearest dot, if applicable
        if (targetDot && targetDot.classList.contains('dot') && targetDot.classList.contains('left')) {

          // If there is a connection already, don't allow a new one
          const existingConnectionIndex = this.connections.findIndex((connection) => connection.targetId === targetDot.dataset.id);
          if (existingConnectionIndex !== -1) {
            this.dragging = false;
            this.currentConnection = null;
            return;
          }

          // If the source dot has a connection, remove it
          const existingSourceConnectionIndex = this.connections.findIndex((connection) => connection.id === this.currentConnection.id);
          if (existingSourceConnectionIndex !== -1) {
            this.connections.splice(existingSourceConnectionIndex, 1);
          }

          const rect = targetDot.getBoundingClientRect();
          this.currentConnection.x2 = rect.left + rect.width / 2 - containerRect.left;
          this.currentConnection.y2 = rect.top + rect.height / 2 - containerRect.top;

          // Store the ID of the target dot
          this.currentConnection.targetId = targetDot.dataset.id;
          this.currentConnection.targetSide = targetDot.classList.contains('left') ? 'left' : 'right';
          this.currentConnection.color = colorPalette[this.connections.length % colorPalette.length];

          // Set the input dot color to the connection color
          const input = this.inputs.find((input) => input.id === this.currentConnection.id);
          if (input) {
            input.color = this.currentConnection.color;
          }

          this.emitChanges();
        } else {
          // Cancel drag if not connected to a valid target dot
          this.dragging = false;
          this.currentConnection = null;
          return;
        }

        // Add the completed connection to the connections array
        this.connections.push({ ...this.currentConnection });

        // Reset dragging state
        this.dragging = false;
        this.currentConnection = null;

        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.endDrag);
      }
    },
    // Recalculate lines on screen resize
    recalculateLines() {
      if (!this.connections.length) return;

      const containerRect = this.$refs.container.getBoundingClientRect();

      this.connections.forEach((connection) => {
        // Find the dots by their IDs and sides
        const startDot = document.querySelector(`.dot[data-id="${connection.id}"].${connection.side}`);
        const targetDot = document.querySelector(`.dot[data-id="${connection.targetId}"].${connection.targetSide}`);

        if (startDot && targetDot) {
          const startRect = startDot.getBoundingClientRect();
          const targetRect = targetDot.getBoundingClientRect();

          // Update the coordinates based on the new dot positions
          connection.x1 = startRect.left + startRect.width / 2 - containerRect.left;
          connection.y1 = startRect.top + startRect.height / 2 - containerRect.top;

          connection.x2 = targetRect.left + targetRect.width / 2 - containerRect.left;
          connection.y2 = targetRect.top + targetRect.height / 2 - containerRect.top;
        }
      });

      this.$forceUpdate();
    },
    resetConnections() {
      this.connections = [];
      this.inputs.forEach((input) => {
        input.color = null;
      });
    },
    addInput() {
      this.inputs.push({ id: this.inputs.length + 1, leftValue: '', rightValue: '' });
    },
    removeInput(id) {
      const inputIndex = this.inputs.findIndex((input) => input.id === id);
      if (inputIndex !== -1) {
        this.inputs.splice(inputIndex, 1);
      }

      // Remove any connections associated with the removed input
      this.connections = this.connections.filter((connection) => connection.id !== id && connection.targetId !== id);
    },
    onFileSelected(file, id, side) {
      const input = this.inputs.find((input) => input.id === id);
      if (input) {
        if (side === 'right') {
          input.attachRight = file;
        } else {
          input.attachLeft = file;
        }
      }

      this.emitChanges();
    },
    emitChanges() {
      const tempConnections = this.connections.map((connection) => {
        return {
          id: connection.id,
          side: connection.side,
          targetId: connection.targetId,
          targetSide: connection.targetSide,
          color: connection.color,
        };
      });

      this.onChange({
        connections: tempConnections,
        inputs: this.inputs,
      });
    }
  },
};
</script>

<style scoped>
.match-container {
  position: relative;
}

.input-col {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.input-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.input-spacer {
  width:  40px;
  height: 1px;
}

.dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #667085;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 3px solid #fff;
  cursor: pointer;
  z-index: 2;
}

.dot.right {
  right: -8px;
  background-color: #00D68F;
}

.dot.left {
  left: -8px;
}

.connection-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.bottom-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.bottom-buttons-wrapper button {
  background-color: transparent;
  color: #667085;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 12px;
  font-family: 'euclid_medium', sans-serif;
  margin-top: 15px;
  transition: color 0.2s;
}

.bottom-buttons-wrapper button:hover {
  color: #4A68F8;
}

.remove-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.remove-wrapper img {
  width: 24px;
  padding: 5px;
  border-radius: 50%;
}

.remove-wrapper img:hover {
  background-color: #f4cac6;
}

.form-input {
  font-size: 14px;
}

svg {
  shape-rendering: crispEdges;
  overflow: visible;
}
</style>
