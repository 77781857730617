import axios from '../axios';

const depService = {
    getMenuModules() {
        return axios.get(`/v1/admin/module/store`);
    },
    getDeps(companyId) {
        return axios.get(`/v1/admin/department/store?companyId=${companyId}`);
    },
    getDep(id) {
        return axios.get(`/v1/admin/department/getFromId?departmentId=${id}`);
    },
    addDep(data) {
        return axios.post(`/v1/admin/department/add`, data);
    },
    updateDep(data) {
        return axios.put(`/v1/admin/department/update`, data);
    },
    deleteDep(depId) {
        return axios.delete(`/v1/admin/department/delete/${depId}`);
    }
}

export default depService;
