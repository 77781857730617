<template>
  <Layout>
    <h3 class="page-title">{{ $t('all_tests') }}</h3>
    <div class="filter-wrapper">
      <TestFilters />

      <div class="justify-end-div">
        <ExpandableSearch />
        <router-link to="/test/new">
          <div class="justify-end-add-btn no-text-select">
            <img src="../assets/images/plus.svg" />
            <span class="justify-end-addbtn-txt">{{ $t('add_new') }}</span>
          </div>
        </router-link>
      </div>
    </div>

    <div v-if="tests.total <= 0 && !isLoading" class="no-tests-wrapper">
      <img src="../assets/images/noTests.png" class="no-tests-image">
      <span class="no-tests-text">
        {{$t('not_created_test_yet')}}
      </span>
      <span class="no-tests-description">
        {{$t('add_new_created_test')}}
      </span>
    </div>

    <div v-else class="tests-grid">
      <router-link v-for="item in tests.data"  v-if="!isLoading" :to="`/test/${item.testId}`">
        <div  class="test-card no-text-select">
          <div class="status-tag" :class="item.isPublish ? 'Published' : 'Draft'">
            <div class="status-dot" :class="item.isPublish ? 'Published' : 'Draft'">
            </div>
            <span>{{item.isPublish ? $t('published') : $t('draft')}}</span>
          </div>


          <span class="test-date">{{formatDate(item.created_at)}}</span>
          <span class="test-title">{{item.name}}</span>
          <div class="test-info">
            <img src="@/assets/images/datepicker.svg" class="test-info-icon"/>
            <span class="test-info-text">{{ formatDate(item.startAt) }} - {{ formatDate(item.finishAt) }}</span>

            <div class="dot"></div>

            <div class="test-time-row" v-if="item.remaining.isPast === 0">
              <img
                  src="@/assets/images/clockOutlineRed.svg"
                  class="test-info-icon"
              />
              <span class="test-info-text red">{{$t('time_is_up')}}</span>
            </div>

            <div class="test-time-row"  v-if="item.remaining.isPast === 1">
              <img
                  src="@/assets/images/clockOutlineGreen.svg"
                  class="test-info-icon"
              />
              <span class="test-info-text green">
              {{ item.remaining.time }}
            </span>
            </div>

            <div class="test-time-row"  v-else-if="item.remaining.isPast === 2">
              <img
                  src="@/assets/images/clockOutlineYellow.svg"
                  class="test-info-icon"
              />
              <span class="test-info-text yellow">
              {{ item.remaining.time }}
            </span>
            </div>
          </div>

          <div class="info-row">
            <span class="info-tag">{{$t('companies_count', {companiesCount: item.compainesCount})}}</span>
            <span class="info-tag">{{$t('question_count', {questionCount: item.questionsCount})}}</span>
          </div>
        </div>

      </router-link>

      <free-style-shimmer
          :is-loading="isLoading"
          height="200px"
          width="100%"
          border-radius="20px"
          color="#EAECF0"
      />

      <free-style-shimmer
          :is-loading="isLoading"
          height="200px"
          width="100%"
          border-radius="20px"
          color="#EAECF0"
      />

      <free-style-shimmer
          :is-loading="isLoading"
          height="200px"
          width="100%"
          border-radius="20px"
          color="#EAECF0"
      />

    </div>

    <div class="pagination-align-center">
      <Pagination :current-page="currentPage" :total-items="tests.total" :per-page="20" :on-page-change="handlePage"/>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import FilterModal from "@/components/tables/Company/CompanyFilterModal.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import testService from "@/service/testService";
import Pagination from "@/components/Pagination.vue";
import TestFilters from "@/components/tables/Test/TestFilters.vue";

export default {
  components: {
    TestFilters,
    Pagination,
    ExpandableSearch,
    FilterModal,
    Layout
  },

  props: {
    onDuplicateQuestion: {
      type: Function,
      default: () => {}
    },
  },

  data() {
    return {
      isLoading: true,
      tests: {},
      currentPage: 1,
    }

  },

  watch: {
    $route() {
      this.handlePage(1);
    }
  },
  methods: {
    formatDate(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        return date.toLocaleDateString("en", options);
      } catch (e) {
        return '-';
      }
    },

    handlePage(page) {
      if (this.isLoading && this.currentPage !== 1) return;
      this.isLoading = true;
      this.currentPage = page;

      const routeQuery = this.$route.query;
      const tempFilters = [];

      if (routeQuery.searchText) {
        tempFilters.push({
          key: 'searchText',
          value: routeQuery.searchText
        });
      }

      if (routeQuery.startAt) {
        tempFilters.push({
          key: 'startAt',
          value: routeQuery.startAt
        });
      }

      if (routeQuery.startAtEnd) {
        tempFilters.push({
          key: 'startAtEnd',
          value: routeQuery.startAtEnd
        });
      }

      if (routeQuery.finishAt) {
        tempFilters.push({
          key: 'finishAt',
          value: routeQuery.finishAt
        });
      }

      if (routeQuery.finishAtEnd) {
        tempFilters.push({
          key: 'finishAtEnd',
          value: routeQuery.finishAtEnd
        });
      }

      if (routeQuery.isPublish) {
        if(routeQuery.isPublish === "1") {
          tempFilters.push({
            key: 'isPublish',
            value: "1"

          })}
        else if(routeQuery.isPublish==="0"){
          tempFilters.push({
            key: 'isPublish',
            value: "0"
          })
        }
      }

      testService.getTests(page, tempFilters).then((response) => {
        this.isLoading = false;
        this.tests = response.data.data;
      })
    }
  }
}
</script>

<style scoped>
.page-title {
  font-family: euclid_medium, sans-serif;
  font-size: 20px;
  color: #040C2D;
  margin-bottom: 20px;
}
.filter-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.filter-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.filter-dropdown {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}
.dropdown-text {
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
}

.tests-grid {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.test-card {
  width: 100%;
  height: 100%;
  border: 1px solid #EAECF0;
  background-color: #FCFCFD;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.status-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #040c2d;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.status-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.status-tag.Draft {
  background-color: #fad9c6;
}

.status-tag.Published {
  background-color: #ddfbde;
}

.status-dot.Draft {
  background-color: #f09460;
}

.status-dot.Published {
  background-color: #3da33e;
}

.test-date {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #667085;
  margin-top: 30px;
}

.test-title {
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  color: #040C2D;
  margin-bottom: 10px;
}

.info-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: auto;
}

.info-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 10px;
  color: #040C2D;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 12px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #F2F4F7;
}

.justify-end-div{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .tests-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .tests-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.test-info-text {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #667085;
}

.test-info-text.red {
  color: #ff2121;
}

.test-info-text.yellow {
  color: #f1c40f;
}

.dot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #d0d5dd;
}

.test-info {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-bottom: 30px;
}

.test-info-icon {
  width: 24px;
  height: 24px;
}

.test-time-row {
  display: flex;
  height:50px;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
</style>
