<script setup>
import { Handle, Position } from '@vue-flow/core';
import NodeDropdown from "@/components/condition-flow/NodeDropdown.vue";

const props = defineProps(['id', 'data', 'notificationList', 'notificationSelect', 'editDisabled']);

const handleSelect = (value) => {
  if (value === '0') return;

  props.notificationSelect(value, props.id);
};

const mapNotificationSelect = () => {
  return props.notificationList.map(notification => {
    return {
      value: notification.notificationId,
      text: notification.content?.label
    };
  });
};

</script>
<template>
  <div class="node-wrapper">
    <Handle id="input" type="target" :position="Position.Top"/>

    <div class="node-header">
      <img src="../../../assets/images/notificationOutlineDark.svg" alt="icon"/>
      <span class="node-type">{{$t('notification')}}</span>
    </div>

    <div class="node-body">
      <NodeDropdown :search-enabled="true" theme="dark" :on-select="handleSelect" :selected-value="data.notificationId" :options="mapNotificationSelect()" />
    </div>

    <Handle id="output" type="source" :position="Position.Bottom" class="handle-true"/>
    <Handle id="false" type="source" :position="Position.Bottom" class="handle-false"/>
  </div>
</template>

<style scoped>
.node-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 320px;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #040c2d;
  background-color: #fff;
  border-radius: 6px;
}


.node-header {
  width: 100%;
  background-color: #f1f1f1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 10px;
}

.node-header img {
  width: 24px;
  height: 24px;
}

.node-type {
  font-size: 16px;
  color: #101828;
  font-family: 'euclid_regular', sans-serif;
}

.node-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.handle-true {
  position: absolute;
  bottom: -6px;
  left: calc(50% - 10px) ;
  transform: translateX(-50%);
  background-color: #00D68F;
}

.handle-false {
  position: absolute;
  bottom: -6px;
  right: calc(50% - 10px) ;
  transform: translateX(50%);
  background-color: #F44336 !important;
}
</style>
