<template>
  <div class="radial-widget">
    <apexchart type="donut" width="380" :options="{
      ...chartOptions,
      labels: data.labels,
    }" :series="data.series"></apexchart>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: {
        series: [],
        labels: [],
      },
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
            offsetY: 10,
          },
        },
        grid: {
          padding: {
            bottom: -80,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        colors: ['#90a2eb', '#e8bfa6'],
      },
    };
  },
};
</script>

<style scoped>
.radial-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
}
</style>
