<template>
  <div class="card-wrapper">
    <div class="question-header">
      <div class="left-wrapper">
        <div class="question-number-wrapper">
          <span class="question-number">{{questionData.order}}.</span>
        </div>

        <span class="question-type-text" v-if="isPartOfTherapy">{{$t('step')}}: </span>
        <span class="question-type-text" v-else>{{$t('step')}}: </span>

        <div class="question-dropdown" v-if="isPartOfTherapy" v-click-outside="partDropdownOutsideClick">
          <div class="dropdown-button" :class="{'active': partDropdownVisible}" @click="partDropdownVisible = !partDropdownVisible">
            <img src="@/assets/images/questionOutline.png" />
            <span>{{$t('question_type_program')}}</span>
            <img src="@/assets/images/arrowdown.svg" class="arrow" />
          </div>

          <div class="dropdown-items" v-if="partDropdownVisible">
            <div v-for="item in partTypes" class="dropdown-item" @click="() => selectPartType(item.id)">
              <img :src="item.icon" />
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>

        <div class="question-dropdown no-text-select" v-click-outside="dropdownOutsideClick">
          <div class="dropdown-button" :class="{'active': questionDropdownVisible}" @click="questionDropdownVisible = !questionDropdownVisible">
            <img :src="getQuestionTypes.find(type => type.id === selectedTypeID).icon" />
            <span>{{getQuestionTypes.find(type => type.id === selectedTypeID).name}}</span>
            <img src="@/assets/images/arrowdown.svg" class="arrow" />
          </div>

          <div class="dropdown-items" v-if="questionDropdownVisible">
            <div v-for="item in getQuestionTypes" class="dropdown-item" @click="() => selectType(item.id)">
              <img :src="item.icon" />
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>

        <div class="question-dropdown no-text-select" v-click-outside="qdDropdownOutsideClick" v-if="qdList.length > 0 && (selectedTypeID === 2 || selectedTypeID === 3)">
          <div class="dropdown-button" :class="{'active': qdDropdownVisible}" @click="qdDropdownVisible = !qdDropdownVisible">
            <span v-if="questionData.dimensionalId">
              {{dimensionList.find(dimension => dimension.dimensionalId === questionData.dimensionalId).name}}
            </span>
            <span v-else>{{$t('select_a_dimension')}}</span>
            <img src="@/assets/images/arrowdown.svg" class="arrow" />
          </div>

          <div class="dropdown-items" v-if="qdDropdownVisible">
            <div v-for="item in qdList" class="dropdown-item" @click="selectQD(item.dimensionalId)">
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="right-wrapper">
        <img src="@/assets/images/arrow-up.svg" @click="onChangePosition('up', questionData.order)"/>
        <img src="@/assets/images/arrow-down.svg" @click="onChangePosition('down', questionData.order)" />
        <img src="@/assets/images/copyOutline.svg" @click="onDuplicateQuestion(questionData.order)" />
        <img src="@/assets/images/settingsOutline.svg" @click="questionSettingsVisible = true" />
        <img src="@/assets/images/trashOutline.svg" @click="handleQuestionDelete" />
      </div>
    </div>

    <Transition name="slide-up">
      <div v-if="expanded" class="question-body">

        <div v-if="selectedTypeID === 1" class="port-body">
          <span class="title-input">{{$t('what_people_will_learn')}}</span>
          <div class="question-wrapper">
            <textarea style="width: 100%" :placeholder="this.$t('enter_your_welcome_text')" rows="5" v-model="questionData.name" />

            <div class="input-actions">
              <img src="@/assets/images/addImage.svg" @click="addVisualVisible = true"/>
            </div>
          </div>
        </div>

        <div v-if="selectedTypeID === 2">
          <div class="question-wrapper">
            <textarea class="question-input" :placeholder="$t('add_your_question_here')" v-model="questionData.name" v-textarea-autoresize></textarea>

            <div class="input-actions">
              <img src="@/assets/images/addNote.svg" @click="addNoteVisible = true"/>
              <img src="@/assets/images/addImage.svg" @click="addVisualVisible = true"/>
            </div>
          </div>

          <Transition name="slide-up">
            <div class="description-wrapper" v-if="addNoteVisible">
              <span>{{$t('add_note')}}:</span>
              <input type="text" v-model="questionData.description"/>
              <img src="@/assets/images/crossCircle.svg" @click="addNoteVisible = false" />
            </div>
          </Transition>

          <div class="answers-body">
            <h4>{{$t('answers')}}</h4>
            <draggable
                v-model="questionData.content.options"
                item-key="id">
              <template #item="{element}">
                <div class="answer-input-wrapper">
                  <div class="answer-input-row">
                    <img src="@/assets/images/drag.svg" />
                    <div class="answer-checkbox" @click="() => toggleFeedback(element)">
                      <img src="@/assets/images/checkboxchecked-circle.svg" v-if="element.feedback" />
                      <img src="@/assets/images/checkbox-circle.svg" v-else />
                    </div>
                    <input class="answer-input" :placeholder="this.$t('add_your_answer_here')" v-model="element.name" />
                    <input type="number" class="score-input" :placeholder="this.$t('score')" v-model="element.score" />
                    <SelectBox class="w-100" v-if="adList.length > 0" :options="adList" :placeholder="this.$t('select_a_dimension')" :selected-value="element.dimensionalId" :on-select="(selected) => handleOptionDimensionSelect(selected, element)"/>
                    <img src="@/assets/images/addImage.svg" @click="element.addVisualVisible = true" />
                    <img src="@/assets/images/addNote.svg" @click="element.addFeedbackVisible = true" />
                    <img src="@/assets/images/trashOutline.svg" @click="() => handleDelete(element.id)" />
                  </div>

                  <Transition name="slide-up">
                    <div class="description-wrapper answer" v-if="element.addFeedbackVisible || element.feedbackText">
                      <img src="@/assets/images/crossCircle.svg" @click="() => {
                        element.addFeedbackVisible = false
                        element.feedbackText = ''
                      }" />
                      <span>Add Feedback:</span>
                      <input type="text" v-model="element.feedbackText"/>
                    </div>
                  </Transition>

                  <AddSingleVisualModal
                      :visible="element.addVisualVisible"
                      :on-hide="() => element.addVisualVisible = false"
                      :data="element"
                  />
                </div>
              </template>
            </draggable>

            <button class="add-answer-button no-text-select" @click="addAnswer">
              <img src="@/assets/images/plusBlue.svg" />
              <span>{{$t('add_an_answer')}}</span>
            </button>
          </div>
        </div>

        <div v-if="selectedTypeID === 3">
          <div class="question-wrapper">
            <textarea class="question-input" :placeholder="$t('add_your_question_here')" v-model="questionData.name" v-textarea-autoresize></textarea>

            <div class="input-actions">
              <img src="@/assets/images/addNote.svg" @click="addNoteVisible = true"/>
              <img src="@/assets/images/addImage.svg" @click="addVisualVisible = true"/>
            </div>
          </div>

          <Transition name="slide-up">
            <div class="description-wrapper" v-if="addNoteVisible">
              <span>{{$t('add_note')}}:</span>
              <input type="text" v-model="questionData.description"/>
              <img src="@/assets/images/crossCircle.svg" @click="addNoteVisible = false" />
            </div>
          </Transition>
          <div class="answers-body">
            <h4>{{$t('answers')}}</h4>
            <draggable
                v-model="questionData.content.options"
                item-key="id">
              <template #item="{element}">
                <div class="answer-input-wrapper">
                  <div class="answer-input-row">
                    <img src="@/assets/images/drag.svg" />
                    <div class="answer-checkbox" @click="() => toggleFeedback(element, true)">
                      <img src="@/assets/images/selectedcheckbox.svg" v-if="element.feedback" />
                      <img src="@/assets/images/checkbox.svg" v-else />
                    </div>
                    <input class="answer-input" :placeholder="this.$t('add_your_answer_here')" v-model="element.name" />
                    <input type="number" class="score-input" :placeholder="this.$t('score')" v-model="element.score" />
                    <SelectBox class="w-100" v-if="adList.length > 0" :options="adList" :placeholder="this.$t('select_a_dimension')" :selected-value="element.dimensionalId" :on-select="(selected) => handleOptionDimensionSelect(selected, element)"/>
                    <img src="@/assets/images/addImage.svg" @click="element.addVisualVisible = true" />
                    <img src="@/assets/images/addNote.svg" @click="element.addFeedbackVisible = true" />
                    <img src="@/assets/images/trashOutline.svg" @click="() => handleDelete(element.id)" />
                  </div>

                  <Transition name="slide-up">
                    <div class="description-wrapper answer" v-if="element.addFeedbackVisible || element.feedbackText">
                      <img src="@/assets/images/crossCircle.svg" @click="() => {
                        element.addFeedbackVisible = false
                        element.feedbackText = ''
                      }" />
                      <span>Add Feedback:</span>
                      <input type="text" v-model="element.feedbackText"/>
                    </div>
                  </Transition>

                  <AddSingleVisualModal
                      :visible="element.addVisualVisible"
                      :on-hide="() => element.addVisualVisible = false"
                      :data="element"
                  />
                </div>
              </template>
            </draggable>
            <button class="add-answer-button no-text-select" @click="addAnswer">
              <img src="@/assets/images/plusBlue.svg" />
              <span>{{$t('add_an_answer')}}</span>
            </button>
          </div>
        </div>

        <div v-if="selectedTypeID === 4">
          <div class="question-wrapper">
            <textarea class="question-input" :placeholder="$t('add_your_question_here')" v-model="questionData.name" v-textarea-autoresize></textarea>

            <div class="input-actions">
              <img src="@/assets/images/addNote.svg" @click="addNoteVisible = true"/>
              <img src="@/assets/images/addImage.svg" @click="addVisualVisible = true"/>
            </div>
          </div>

          <Transition name="slide-up">
            <div class="description-wrapper" v-if="addNoteVisible">
              <span>{{$t('add_note')}}:</span>
              <input type="text" v-model="questionData.description"/>
              <img src="@/assets/images/crossCircle.svg" @click="addNoteVisible = false" />
            </div>
          </Transition>

          <div class="answers-body">
            <h4>{{$t('answers')}}</h4>
            <draggable
                v-model="questionData.content.options"
                item-key="id">
              <template #item="{element}">
                <div class="answer-input-wrapper">
                  <div class="answer-input-row">
                    <img src="@/assets/images/drag.svg" />
                    <div class="answer-checkbox" @click="() => toggleFeedback(element)">
                      <img src="@/assets/images/checkboxchecked-circle.svg" v-if="element.feedback" />
                      <img src="@/assets/images/checkbox-circle.svg" v-else />
                    </div>
                    <input class="answer-input" :placeholder="this.$t('add_your_answer_here')" :value="element.name" />
                    <input type="number" class="score-input" :placeholder="this.$t('score')" v-model="element.score" />
                    <img src="@/assets/images/addImage.svg" @click="element.addVisualVisible = true"/>
                    <img src="@/assets/images/addNote.svg" @click="element.addFeedbackVisible = true" />
                  </div>

                  <Transition name="slide-up">
                    <div class="description-wrapper answer" v-if="element.addFeedbackVisible || element.feedbackText">
                      <img src="@/assets/images/crossCircle.svg" @click="() => {
                        element.addFeedbackVisible = false
                        element.feedbackText = ''
                      }" />
                      <span>Add Feedback:</span>
                      <input type="text" v-model="element.feedbackText"/>
                    </div>
                  </Transition>

                  <AddSingleVisualModal
                      :visible="element.addVisualVisible"
                      :on-hide="() => element.addVisualVisible = false"
                      :data="element"
                  />
                </div>
              </template>
            </draggable>
          </div>
        </div>

        <div v-if="selectedTypeID === 5">
          <div class="question-wrapper">
            <textarea class="question-input" :placeholder="$t('add_your_question_here')" v-model="questionData.name" v-textarea-autoresize></textarea>

            <div class="input-actions">
              <img src="@/assets/images/addNote.svg" @click="addNoteVisible = true"/>
              <img src="@/assets/images/addImage.svg" @click="addVisualVisible = true"/>
            </div>
          </div>

          <Transition name="slide-up">
            <div class="description-wrapper" v-if="addNoteVisible">
              <span>{{$t('add_note')}}:</span>
              <input type="text" v-model="questionData.description"/>
              <img src="@/assets/images/crossCircle.svg" @click="addNoteVisible = false" />
            </div>
          </Transition>

          <div class="answers-body">
            <div class="answer-input-row">
              <div class="input-row">
                <span>{{$t('min')}}</span>
                <input class="form-input" type="number" :placeholder="this.$t('min')" v-model="questionData.content.settings.limitedRange.min" />
              </div>
              <div class="input-row">
                <span>{{$t('max')}}</span>
                <input class="form-input" type="number" :placeholder="this.$t('max')" v-model="questionData.content.settings.limitedRange.max"/>
              </div>
              </div>
          </div>
        </div>

        <div v-if="selectedTypeID === 6">
          <div class="question-wrapper mb-2">
            <textarea class="question-input" :placeholder="$t('add_your_question_here')" v-model="questionData.name" v-textarea-autoresize></textarea>

            <div class="input-actions">
              <img src="@/assets/images/addNote.svg" @click="addNoteVisible = true"/>
              <img src="@/assets/images/addImage.svg" @click="addVisualVisible = true"/>
            </div>
          </div>

          <textarea class="question-input" :placeholder="this.$t('textarea_placeholder')" v-model="questionData.content.settings.placeholder" v-textarea-autoresize></textarea>

          <Transition name="slide-up">
            <div class="description-wrapper" v-if="addNoteVisible">
              <span>{{$t('add_note')}}:</span>
              <input type="text" v-model="questionData.description"/>
              <img src="@/assets/images/crossCircle.svg" @click="addNoteVisible = false" />
            </div>
          </Transition>


          <div class="answers-body">
            <div class="answer-input-row">
              <div class="input-row">
                <span>{{$t('min_character')}}</span>
                <input class="form-input" type="number" :placeholder="this.$t('min')" v-model="questionData.content.settings.characterLimit.min" />
              </div>
              <div class="input-row">
                <span>{{$t('max_character')}}</span>
                <input class="form-input" type="number" :placeholder="this.$t('max')" v-model="questionData.content.settings.characterLimit.max"/>
              </div>
            </div>
          </div>

        </div>

        <div v-if="selectedTypeID === 7">
          <div class="question-wrapper">
            <textarea class="question-input" :placeholder="$t('add_your_question_here')" v-model="questionData.name" v-textarea-autoresize></textarea>

            <div class="input-actions">
              <img src="@/assets/images/addNote.svg" @click="addNoteVisible = true"/>
              <img src="@/assets/images/addImage.svg" @click="addVisualVisible = true"/>
            </div>
          </div>

          <Transition name="slide-up">
            <div class="description-wrapper" v-if="addNoteVisible">
              <span>{{$t('add_note')}}:</span>
              <input type="text" v-model="questionData.description"/>
              <img src="@/assets/images/crossCircle.svg" @click="addNoteVisible = false" />
            </div>
          </Transition>


          <div class="answers-body">
            <div class="answer-input-row">
              <div class="input-row">
                <span>{{$t('min_grade')}}</span>
                <input class="form-input" type="number" :placeholder="this.$t('min')"  v-model="questionData.content.settings.limitedRange.min" />
              </div>
              <div class="input-row">
                <span>{{$t('max_grade')}}</span>
                <input class="form-input" type="number" :placeholder="this.$t('max')"  v-model="questionData.content.settings.limitedRange.max" />
              </div>
            </div>
          </div>

        </div>

        <div v-if="selectedTypeID === 8">
          <div class="question-wrapper">
            <textarea class="question-input" :placeholder="$t('add_your_question_here')" v-model="questionData.name" v-textarea-autoresize></textarea>

            <div class="input-actions">
              <img src="@/assets/images/addNote.svg" @click="addNoteVisible = true"/>
              <img src="@/assets/images/addImage.svg" @click="addVisualVisible = true"/>
            </div>
          </div>

          <Transition name="slide-up">
            <div class="description-wrapper" v-if="addNoteVisible">
              <span>{{$t('add_note')}}:</span>
              <input type="text" v-model="questionData.description"/>
              <img src="@/assets/images/crossCircle.svg" @click="addNoteVisible = false" />
            </div>
          </Transition>

          <div class="answers-body">
            <div class="answer-input-row">
              <div class="input-row">
                <span>{{$t('min_rating')}}</span>
                <input class="form-input" type="number" :placeholder="this.$t('min')" v-model="questionData.content.settings.limitedRange.min" />
              </div>
              <div class="input-row">
                <span>{{$t('max_rating')}}</span>
                <input class="form-input" type="number" :placeholder="this.$t('max')" v-model="questionData.content.settings.limitedRange.max" />
              </div>
            </div>
          </div>

        </div>

        <div v-if="selectedTypeID === 9">
          <div class="question-wrapper">
            <textarea class="question-input" :placeholder="$t('add_your_question_here')" v-model="questionData.name" v-textarea-autoresize></textarea>

            <div class="input-actions">
              <img src="@/assets/images/addNote.svg" @click="addNoteVisible = true"/>
              <img src="@/assets/images/addImage.svg" @click="addVisualVisible = true"/>
            </div>
          </div>

          <Transition name="slide-up">
            <div class="description-wrapper" v-if="addNoteVisible">
              <span>{{$t('add_note')}}:</span>
              <input type="text" v-model="questionData.description"/>
              <img src="@/assets/images/crossCircle.svg" @click="addNoteVisible = false" />
            </div>
          </Transition>

          <div class="answers-body">
            <h4>{{$t('answers')}}</h4>
            <draggable
                v-model="questionData.content.options"
                item-key="id">
              <template #item="{element}">
                <div class="answer-input-row" >
                  <img src="@/assets/images/drag.svg" />
                  <input class="answer-input" :placeholder="this.$t('add_your_answer_here')" v-model="element.name" />
                  <input type="number" class="score-input" :placeholder="this.$t('score')" v-model="element.score" />
                  <img src="@/assets/images/trashOutline.svg" @click="() => handleDelete(element.id)" />
                </div>
              </template>
            </draggable>

            <button class="add-answer-button no-text-select" @click="addAnswer">
              <img src="@/assets/images/plusBlue.svg" />
              <span>{{$t('add_an_answer')}}</span>
            </button>
          </div>
        </div>

        <div v-if="selectedTypeID === 10">
          <span class="title-input">{{$t('summary_type')}}</span>
          <div class="question-wrapper mb-2">

            <textarea class="question-input" :placeholder="this.$t('add_your_summary_title')" v-model="questionData.name"  v-textarea-autoresize></textarea>

            <div class="input-actions">
              <img src="@/assets/images/addImage.svg" @click="addVisualVisible = true"/>
            </div>
          </div>
          <textarea style="width: 100%" :placeholder="this.$t('add_a_summary_text')" rows="5" v-model="questionData.description" />
        </div>

        <div v-if="selectedTypeID === 11">
          <WordTagger
              :free-text="questionData.content.settings.blankFillFreeText"
              :full-text="questionData.name"
              :blank-out-words="questionData.content.blankOutWords"
              :other-words="questionData.content.otherWords"
              :on-change="handleFillBlankChange"
          />
        </div>

        <div v-if="selectedTypeID === 12">
          <textarea class="question-input mb-3" :placeholder="$t('add_your_question_here')" v-model="questionData.name" v-textarea-autoresize></textarea>

          <MatchText
              :connection-values="questionData.content.connections"
              :input-values="questionData.content.inputs"
              :left-part-image="questionData.content.settings.leftPartImage || null"
              :right-part-image="questionData.content.settings.rightPartImage || null"
              :on-change="handleMatchChange"
          />
        </div>

      </div>
    </Transition>

    <div class="question-footer">
      <div class="question-dropdown no-text-select" v-click-outside="tagsDropdownOutsideClick">
        <div class="dropdown-button" :class="{'active': tagsDropdownVisible}" @click="tagsDropdownVisible = !tagsDropdownVisible">
          <span v-if="!questionData.tagIds || questionData.tagIds.length === 0">{{$t('select_tags_question')}}</span>
          <span v-else>{{questionData.tagIds.length}} {{$t('tags_selected')}}</span>
          <img src="@/assets/images/arrowdown.svg" class="arrow" />
        </div>

        <div class="dropdown-items tags scroll-design" v-if="tagsDropdownVisible">
          <div v-for="item in tagList" class="dropdown-item" @click="() => toggleTag(item.id)">
            <img v-if="checkTagSelected(item.id)" class="check" src="@/assets/images/checkboxChecked.svg" />
            <img v-else class="check" src="@/assets/images/checkbox.svg" />
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>

      <button class="header-button" v-if="isPartOfTherapy" @click="onSaveForCondition">
        <span>{{$t('save_for_condition')}}</span>
      </button>
    </div>

    <div class="toggle-card" :class="{'closed': !expanded}" @click="toggleCard">
      <img src="@/assets/images/cross.svg" />
    </div>

    <AddLinkModal :visible="addLinkVisible" :on-hide="hideAddLink" />
    <AddVisualModal
        :visible="addVisualVisible"
        :on-hide="hideAddVisual"
        v-model="questionData.content.attach"
    />
    <QuestionSettingsModal
        :visible="questionSettingsVisible"
        :on-hide="hideQuestionSettings"
        :settings="questionData.content.settings"
        :selected-type="selectedTypeID"
        :is-part-of-therapy="isPartOfTherapy"
        :test-list="testList"
    />
  </div>
</template>

<script>
import checkCircle from "@/assets/images/checkCircle.svg";
import taskOutline from "@/assets/images/taskOutline.svg";
import noteOutline from "@/assets/images/noteOutline.svg";
import calculatorOutline from "@/assets/images/calculatorOutline.svg";
import receiptOutline from "@/assets/images/receiptOutline.svg";
import settingOutline from "@/assets/images/settingOutline.svg";
import starOutline from "@/assets/images/starOutline.svg";
import GradeInput from "@/components/GradeInput.vue";
import StarInput from "@/components/StarInput.vue";
import AddLinkModal from "@/components/modals/AddLinkModal.vue";
import AddVisualModal from "@/components/modals/AddVisualModal.vue";
import QuestionSettingsModal from "@/components/modals/QuestionSettingsModal.vue";
import draggable from "vuedraggable";
import textOutline from "@/assets/images/textOutline.svg";
import audioOutline from "@/assets/images/audioOutline.png";
import videoOutline from "@/assets/images/videoOutline.png";
import questionOutline from "@/assets/images/questionOutline.png";
import receipt2Outline from "@/assets/images/receipt2Outline.png";
import shieldOutline from "@/assets/images/shieldOutline.png";
import failedShieldOutline from "@/assets/images/failedShieldOutline.svg";
import fillTheBlankOutline from "@/assets/images/fillTheBlankOutline.svg";
import matchOutline from "@/assets/images/matchOutline.svg";

import messageOutline from "@/assets/images/messageOutline.svg";
import dragOutline from "@/assets/images/dragOutline.svg";
import AddSingleVisualModal from "@/components/modals/AddSingleVisualModal.vue";
import SelectBox from "@/components/SelectBox.vue";
import WordTagger from "@/components/question/WordTagger.vue";
import MatchText from "@/components/question/MatchText.vue";

export default {
  components: {
    WordTagger,
    MatchText,
    SelectBox,
    AddSingleVisualModal,
    QuestionSettingsModal,
    AddVisualModal,
    AddLinkModal,
    StarInput,
    GradeInput,
    draggable
  },
  props: {
    onDelete: {
      type: Function,
      default: () => {}
    },
    questionUpdate: {
      type: Function,
      default: () => {}
    },
    data: {
      type: Object,
      default: {}
    },
    onChangePosition: {
      type: Function,
      default: () => {}
    },
    onDuplicateQuestion: {
      type: Function,
      default: () => {}
    },
    isPartOfTherapy: {
      type: Boolean,
      default: false
    },
    onTherapyPartTypeChange: {
      type: Function,
      default: () => {}
    },
    onSaveForCondition: {
      type: Function,
      default: () => {}
    },
    testList: {
      type: Array,
      default: []
    },
    tagList: {
      type: Array,
      default: []
    },
    dimensionList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      expanded: true,
      selectedTypeID: 1,
      questionDropdownVisible: false,

      tagsDropdownVisible: false,

      questionOrder: 1,
      questionTypes: [
        {
          id: 1,
          name: this.$t('welcome_text'),
          icon: textOutline,
          hideInPart: false,
          data:  {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "welcomeText",
              attach: [],
              settings: {
                required: true,
              },
            }
          }
        },
        {
          id: 2,
          name: this.$t('single_choice'),
          icon: checkCircle,
          hideInPart: false,
          data: {
            name: "",
            status: true,
            content: {
              type: "single",
              settings: {
                required: true,
                randomOrder: false,
                other: false,
                grading: 0
              },
              attach: [],
              options: [
                {
                  id: 0,
                  name: "Option A",
                  score: 0
                },
                {
                  id: 1,
                  name: "Option B",
                  score: 0
                },
              ]
            },
            order: 1,
            waitingTime: null
          },
        },
        {
          id: 3,
          name: this.$t('multiple_choice'),
          icon: taskOutline,
          hideInPart: false,
          data: {
            name: "",
            status: true,
            content: {
              type: "multiple",
              settings: {
                required: true,
                randomOrder: false,
                other: false,
                grading: 0
              },
              attach: [],
              options: [
                {
                  id: 0,
                  name: "Option A",
                  score: 0
                },
                {
                  id: 1,
                  name: "Option B",
                  score: 0
                },
              ]
            },
            order: 1,
            waitingTime: null
          },
        },
        {
          id: 4,
          name: this.$t('yes_no_question'),
          icon: noteOutline,
          hideInPart: false,
          data: {
            status: true,
            content: {
              type: "yesNo",
              settings: {
                required: true,
                other: true,
              },
              attach: [],
              options: [
                {
                  id: 0,
                  name: "Yes",
                  score: 0
                },
                {
                  id: 1,
                  name: "No",
                  score: 0
                },
              ]
            }
          },
        },
        {
          id: 5,
          name: this.$t('number_question'),
          type: "number",
          icon: calculatorOutline,
          hideInPart: false,
          data: {
            status: true,
            content: {
              type: "number",
              attach: [],
              settings: {
                required: 1,
                limitedRange: {
                  min: 1,
                  max: 10
                }
              },
            }
          },
        },
        {
          id: 6,
          name: this.$t('text_question'),
          type: "text",
          icon: receiptOutline,
          hideInPart: false,
          data: {
            status: true,
            content: {
              type: "text",
              attach: [],
              settings: {
                characterLimit: {
                  min: 1,
                  max: 10
                },
                multipleParagraph: true
              },
            }
          },
        },
        {
          id: 7,
          name: this.$t('grading_question'),
          type: "grading",
          icon: settingOutline,
          hideInPart: false,
          data: {
            status: true,
            content: {
              type: "grading",
              attach: [],
              settings: {
                limitedRange: {
                  min: 1,
                  max: 10
                },
                required: true,
              },
            }
          },
        },
        {
          id: 8,
          name: this.$t('rating_question'),
          type: "rating",
          icon: starOutline,
          hideInPart: false,
          data: {
            status: true,
            content: {
              type: "rating",
              attach: [],
              settings: {
                limitedRange: {
                  min: 1,
                  max: 10
                },
                required: true,
              },
            }
          },
        },
        {
          id: 9,
          name: this.$t('drag_drop_question'),
          icon: dragOutline,
          hideInPart: false,
          data: {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "dragAndDrop",
              attach: [],
              settings: {
                drawer: false,
                required: true,
                chat: false,
                where: []
              },
              options: [
                {
                  id: 0,
                  name: "Drag A"
                },
                {
                  id: 1,
                  name: "Drag B",
                },
              ]
            },
          }
        },
        {
          id: 10,
          name: this.$t('summary_type'),
          icon: receipt2Outline,
          hideInPart: true,
          data:  {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "summary",
              attach: [],
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
        {
          id: 11,
          name: this.$t('fill_blank_type'),
          icon: fillTheBlankOutline,
          hideInPart: false,
          data:  {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "fillTheBlank",
              attach: [],
              blankOutWords: [],
              otherWords: [],
              settings: {
                drawer: false,
                required: true,
                blankFillFreeText: false,
              },
            }
          }
        },
        {
          id: 12,
          name: this.$t('match_type'),
          icon: matchOutline,
          hideInPart: false,
          data:  {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "matchType",
              attach: [],
              settings: {
                connections: [],
                inputs: [],
                drawer: false,
                required: true,
                leftPartImage: true,
                rightPartImage: false,
              },
            }
          }
        },
      ],
      addLinkVisible: false,
      addVisualVisible: false,
      addNoteVisible: false,
      questionSettingsVisible: false,
      qdDropdownVisible: false,
      qdList: [],
      adList: [],
      questionData: {},

      partTypes: [
        {
          id: 1,
          name: this.$t('welcome_text'),
          icon: textOutline,
          data:  {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "welcomeText",
              attach: [],
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
        {
          id: 2,
          name: this.$t('audio_type'),
          icon: audioOutline,
          data: {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "audio",
              attach: [
                {
                  name: "Audio",
                  attach: null,
                  type: "audio"
                },
                {
                  name: "Cover Photo",
                  attach: null,
                  type: "cover"
                }
              ],
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
        {
          id: 3,
          name: this.$t('video_type'),
          icon: videoOutline,
          data: {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "audio",
              attach: [
                {
                  name: "Audio",
                  attach: null,
                  type: "audio"
                },
                {
                  name: "Cover Photo",
                  attach: null,
                  type: "cover"
                }
              ],
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
        {
          id: 4,
          name: this.$t('question_type_program'),
          icon: questionOutline,
          data: {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "multiple",
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
              options: [
                {
                  id: 0,
                  name: "Option A"
                },
                {
                  id: 1,
                  name: "Option B",
                },
              ]
            },
          }
        },
        {
          id: 5,
          name: this.$t('summary_type'),
          icon: receipt2Outline,
          data:  {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "summary",
              attach: [],
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
        {
          id: 7,
          name: this.$t('self_chat_type'),
          icon: messageOutline,
          data:  {
            name: "Self Chat",
            status: 1,
            order: 1,
            content: {
              type: "selfChat",
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
        {
          id: 6,
          name: this.$t('success_type'),
          icon: shieldOutline,
          data:  {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "success",
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
        {
          id: 8,
          name: this.$t('failed_type'),
          icon: failedShieldOutline,
          data:  {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "failed",
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
      ],
      partDropdownVisible: false,
    }
  },
  created() {
    this.handleQuestionChanges();
    this.handleDimensionChange();

    if(this.data.tags) {
      this.questionData.tagIds = this.data.tags.map(tag => tag.tagId);
    } else {
      this.questionData.tagIds = [];
    }
  },
  watch: {
    questionData: {
      handler: function (newVal, oldVal) {
        this.questionUpdate(newVal);
      },
      deep: true
    },
    data: {
      handler: function (newVal, oldVal) {
        this.handleQuestionChanges();
      },
      deep: true
    },
    dimensionList: {
      handler: function (newVal, oldVal) {
        this.handleDimensionChange();
      },
      deep: true
    }
  },
  methods: {
    dropdownOutsideClick() {
      this.questionDropdownVisible = false;
    },
    qdDropdownOutsideClick() {
      this.qdDropdownVisible = false;
    },
    partDropdownOutsideClick() {
      this.partDropdownVisible = false;
    },
    tagsDropdownOutsideClick() {
      this.tagsDropdownVisible = false;
    },
    selectType(typeID) {
      this.selectedTypeID = typeID;
      this.questionDropdownVisible = false;

      this.questionData = this.questionTypes.find(type => type.id === typeID).data;
      this.questionData.order = this.questionOrder;

      if (!this.questionData?.content?.attach) {
        this.questionData.content.attach = [];
      }
    },
    toggleTag(tagID) {
      if (!this.questionData.tagIds) {
        this.questionData.tagIds = [];
      }

      if (this.questionData.tagIds.includes(tagID)) {
        this.questionData.tagIds = this.questionData.tagIds.filter(tag => tag !== tagID);
      } else {
        this.questionData.tagIds.push(tagID);
      }
    },
    checkTagSelected(tagId) {
      if (!this.questionData.tagIds) {
        return false;
      }

      return this.questionData.tagIds.includes(tagId);
    },
    selectPartType(typeID) {
      this.questionData = this.partTypes.find(type => type.id === typeID).data;
      this.questionData.order = this.questionOrder;
    },
    addAnswer() {
      this.questionData.content.options.push({
        id: this.questionData.content.options.length + 1,
        name: ""
      });
    },
    handleDelete(id) {
      if (this.questionData.content.options.length === 1) {
        this.questionData.content.options[0] = "";
      } else {
        this.questionData.content.options = this.questionData.content.options.filter(answer => answer.id !== id);
      }
    },
    toggleCard() {
      this.expanded = !this.expanded;
    },
    handleQuestionDelete() {
      this.onDelete(this.questionData.order);
    },
    hideAddLink() {
      this.addLinkVisible = false;
    },
    hideAddVisual() {
      this.addVisualVisible = false;
    },
    hideQuestionSettings() {
      this.questionSettingsVisible = false;
    },
    handleQuestionChanges() {
      if (this.data) {
        if (this.data.content.type === "welcomeText") {
          this.selectedTypeID = 1;
        } else if (this.data.content.type === "single") {
          this.selectedTypeID = 2;
        } else if (this.data.content.type === "multiple") {
          this.selectedTypeID = 3;
        } else if (this.data.content.type === "yesNo") {
          this.selectedTypeID = 4;
        } else if (this.data.content.type === "number") {
          this.selectedTypeID = 5;
        } else if (this.data.content.type === "text") {
          this.selectedTypeID = 6;
        } else if (this.data.content.type === "grading") {
          this.selectedTypeID = 7;
        } else if (this.data.content.type === "rating") {
          this.selectedTypeID = 8;
        } else if (this.data.content.type === "dragAndDrop") {
          this.selectedTypeID = 9;
        } else if (this.data.content.type === "summary") {
          this.selectedTypeID = 10;
        } else if (this.data.content.type === "fillTheBlank") {
          this.selectedTypeID = 11;
        } else if (this.data.content.type === "matchType") {
          this.selectedTypeID = 12;
        }

        this.questionData = this.data;
        this.questionOrder = this.data.order;
      } else {
        this.questionData = this.questionTypes.find(type => type.id === this.selectedTypeID).data;
      }
    },
    handleDimensionChange() {
      if (this.dimensionList.length > 0) {
        this.qdList = this.dimensionList.filter(dimension => dimension.type === 'question');
        this.adList = this.dimensionList.filter(dimension => dimension.type === 'answer').map(ad => {
          return {
            text: ad.name,
            value: ad.dimensionalId
          }
        });
      }
    },
    handleFillBlankChange(data) {
      this.questionData.content.blankOutWords = data.selectedWords;
      this.questionData.content.otherWords = data.otherWords;
      this.questionData.name = data.fullText;
    },
    handleMatchChange(data) {
      this.questionData.content.connections = data.connections;
      this.questionData.content.inputs = data.inputs;
    },
    selectQD(qdID) {
      this.questionData.dimensionalId = qdID;
      this.qdDropdownVisible = false;
    },
    handleOptionDimensionSelect(selected, element) {
      element.dimensionalId = selected;
    },
    toggleFeedback(element, multiple = false) {
      if (multiple) {
        element.feedback = !element.feedback;

        return;
      }

      const firstState = element.feedback;

      if (this.questionData?.content?.options?.length > 0) {
        this.questionData.content.options.forEach(option => {
          option.feedback = false;
        });
      }

      element.feedback = !firstState;
    }
  },
  computed: {
    getQuestionTypes() {
      if (this.isPartOfTherapy) {
        return this.questionTypes.filter(type => type.id !== 1);
      } else {
        return this.questionTypes;
      }
    }
  }
}
</script>

<style scoped>
.card-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 20px 20px 20px 28px;
  display: flex;
  flex-direction: column;
}

.question-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.left-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.right-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px
}

.question-number-wrapper {
  background-color: #040C2D;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-number {
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;
  color: #FFFFFF;
}

.question-body {
  padding: 15px 0;
}

.question-dropdown {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
  white-space: nowrap;
}

.question-dropdown img {
  height: 16px;
  width: 16px;
}

.question-dropdown span {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 12px;
}

.dropdown-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
}

.dropdown-button:hover, .dropdown-button.active {
  background-color: #F2F4F7;
}

.dropdown-button .arrow {
  transition: transform 0.1s linear;
}

.dropdown-button.active .arrow {
  transform: rotate(180deg);
}

.dropdown-items {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 99;
}

.dropdown-items.tags {
  width: 170px;
  height: 200px;
  overflow-y: scroll;
  left: unset;
  right: 0;
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
}

.dropdown-item:hover {
  background-color: #F5F7FA;
}

.question-input, .answer-input, .score-input  {
  height: 100%;
  border: none;
  outline: none;
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  background-color: #F5F5F5;
  color: #282A36;
  padding: 15px;
  border-radius: 8px;
}

.question-input {
  width: 100%;
  padding-right: 56px;
}

.answer-input {
  width: 300px;
}

.score-input {
  width: 100px;
}

.answer-input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.answer-input-row .input-row{
  width: 100%;
  box-sizing: border-box;
}

.answer-input-row span {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #667085;
}

h4 {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
  margin: 0;
  padding: 0;
}

.answers-body {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-answer-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  color: #4A68F8;
  margin-top: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  width: fit-content;
  transition: background-color 0.2s ease-in-out;
}

.add-answer-button:hover {
  background-color: #f3f4ff;
}

.toggle-card {
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  height: 20px;
  width: 20px;
  background-color: #FFFFFF;
  border: 1px solid #D0D5DD;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.toggle-card img {
  height: 9px;
  width: 9px;
}

.toggle-card.closed img {
  transform: rotate(-45deg);
}


.number-input-row input {
  outline: none;
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  background-color: #ffffff;
  color: #282A36;
  padding: 15px;
  width: 100%;
  border-top: 1px solid #D0D5DD;
  border-bottom: 1px solid #D0D5DD;
  border-left: none;
  border-right: none;
  height: 48px;
}

.number-input-row .left-part, .number-input-row .right-part {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
  background-color: #FCFCFD;
  border: 1px solid #D0D5DD;
}

.number-input-row .left-part img, .number-input-row .right-part img {
  height: 16px;
  width: 16px;
}

.number-input-row .left-part {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.number-input-row .right-part {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.question-wrapper {
  position: relative;
  display: flex;
}

.question-wrapper .input-actions {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.description-wrapper {
  display: flex;
  gap: 5px;
  border-bottom: 1px solid #D0D5DD;
  width: 100%;
  padding: 10px 0;
  align-items: center;
}

.description-wrapper.answer {
  border-bottom: none;
}


.description-wrapper span {
  white-space: nowrap;
  font-size: 14px;
  color: #98A2B3;
}

.description-wrapper input {
  border: none;
  outline: none;
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  color: #282A36;
  width: 100%;
}


.header-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
}

.header-button span {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 12px;
}

.header-button:hover {
  background-color: #F2F4F7;
}

.question-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
}

.answer-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.answer-checkbox img {
  height: 16px;
  width: 16px;
}

@media screen and (max-width: 768px) {
  .question-header {
    flex-direction: column;
    gap: 10px;
  }

  .dropdown-items {
    left: auto;
    right: 0;
  }
}
</style>
