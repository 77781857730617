<template>

  <div class="notification-wrapper">
    <div v-click-outside="actionDropdownOutsideClick">
      <img @click="dropdownActive = !dropdownActive" src="@/assets/images/ring.svg" alt="Accounting Icon" />
      <Transition name="slide-fadeUp" appear>
        <div class="notification-dropdown" v-if="dropdownActive">
          <div class="notification-header">
            <span class="notification-header-txt">Bildirimler</span>
            <div class="header-right">
              <span class="import-toggle-text">Tümünü okundu yap</span>
              <img src="../../assets/images/settings.svg" />
            </div>
          </div>
          <div class="tab-select">
            <div class="tab-holder" :class="{'active': tabIndex === 0}" @click="() => changeTab(0)">
              <span class="tab-text">Tümü</span>
              <div v-if="tabIndex===0" class="tab-box" ></div>
            </div>
            <div class="tab-holder" :class="{'active': tabIndex === 1}" @click="() => changeTab(1)">
              <span class="tab-text">Okunmamış</span>
              <div v-if="tabIndex===1" class="tab-box" :class="{'unread': tabIndex === 1}" ></div>
            </div>
            </div>
          <div v-if="tabIndex === 0">
            <div v-for="(Notification, index) in NotificationList" :key="index" class="notification-container">
              <span class="notification-icon-holder">
              <img src="../../assets/images/settings.svg" />
              </span>
              <span class="notification-holder">
                <span class="notification-content-header">{{ Notification.header }}</span>
                <span class="notification-content">{{ Notification.text }}</span>
              </span>
              <div v-click-outside="() => closeDropdown(Notification)" class="more-holder">
                <span class="notification-time">{{ Notification.time }}</span>
                <span @click="toggleDropdown(Notification)" class="more-icon-holder">...</span>
                <div class="more-dropdown" v-if="Notification.moreDropdownActive">
                  <div @click="Notification.selected1 = !Notification.selected1" class="more-dropdown-item">
                    <span class="more-dropdown-text">Okundu Yap</span>
                    <img v-if="Notification.selected1" class="more-dropdown-image" src="../../assets/images/blueTick.svg" alt="Tick Icon" />
                  </div>
                  <div @click="Notification.selected2 = !Notification.selected2" class="more-dropdown-item">
                    <span class="more-dropdown-text">Arşivle</span>
                    <img v-if="Notification.selected2" class="more-dropdown-image" src="../../assets/images/blueTick.svg" alt="Tick Icon" />
                  </div>
                  <div @click="Notification.selected3 = !Notification.selected3" class="more-dropdown-item">
                    <span class="more-dropdown-text">Bildirimleri Kapat</span>
                    <img v-if="Notification.selected3" class="more-dropdown-image" src="../../assets/images/blueTick.svg" alt="Tick Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="tabIndex === 1">
            <div v-for="(Notification, index) in NotReadList" :key="index" class="notification-container">
              <span class="notification-icon-holder">
              <img src="../../assets/images/settings.svg" />
              </span>
              <span class="notification-holder">
                <span class="notification-content-header">{{ Notification.header }}</span>
                <span class="notification-content">{{ Notification.text }}</span>
              </span>
              <div v-click-outside="() => closeDropdown(Notification)" class="more-holder">
                <span class="notification-time">{{ Notification.time }}</span>
                <span @click="toggleDropdown(Notification)" class="more-icon-holder">...</span>
                <div class="more-dropdown" v-if="Notification.moreDropdownActive">
                  <div @click="Notification.selected1 = !Notification.selected1" class="more-dropdown-item">
                    <span class="more-dropdown-text">Okundu Yap</span>
                    <img v-if="Notification.selected1" class="more-dropdown-image" src="../../assets/images/blueTick.svg" alt="Tick Icon" />
                  </div>
                  <div @click="Notification.selected2 = !Notification.selected2" class="more-dropdown-item">
                    <span class="more-dropdown-text">Arşivle</span>
                    <img v-if="Notification.selected2" class="more-dropdown-image" src="../../assets/images/blueTick.svg" alt="Tick Icon" />
                  </div>
                  <div @click="Notification.selected3 = !Notification.selected3" class="more-dropdown-item">
                    <span class="more-dropdown-text">Bildirimleri Kapat</span>
                    <img v-if="Notification.selected3" class="more-dropdown-image" src="../../assets/images/blueTick.svg" alt="Tick Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="all-notifications-area">
            <span class="all-notifications-area-text">Tümü</span>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import FileDrop from "@/components/FileDrop.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";

export default {
  components: {SuccessModal,FileDrop},

  data() {
    return {
      tabIndex:0,
      NotReadList:{
        Notification1:{
          header:"Bu bildirim okunmadı",
          text:"Demo File.pdf",
          time:"23m",
        },
        Notification2:{
          header:"Bu da okunmadı ",
          text:"27.11.2024 tarihinde 'User' ile mülakatın var",
          time:"44m",
        }

      },
      NotificationList:{
        Notification1:{
          header:"Onayını bekleyen bir dosya var",
          text:"Demo File.pdf",
          time:"23m",
        },
        Notification2:{
          header:"Mülakatın Yaklaşıyor ",
          text:"27.11.2024 tarihinde 'User' ile mülakatın var",
          time:"44m",
        }

      },
      dropdownActive: false,
    }
  },
  methods: {
    changeTab(index) {
      this.tabIndex = index;
    },
    actionDropdownOutsideClick() {
      this.dropdownActive = false;
    },
    toggleDropdown(Notification) {
      Notification.moreDropdownActive = !Notification.moreDropdownActive;
    },
    closeDropdown(Notification) {
      Notification.moreDropdownActive = false;  // Dışa tıklanınca dropdown'ı kapat
    },
  }

}
</script>

<style scoped>
.tab-box{
  width: 35.88px;
  height: 3px;
  background-color: #2E70E8;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: center;
}

.tab-box.unread{
  width: 80.06px;
}

.all-notifications-area-text{
  font-family: euclid_semi_bold, sans-serif;
  margin-right: 15px;
  font-size: 14px;
  color: #2E70E8;
  cursor: pointer;
}

.all-notifications-area{
  padding: 20px 16px 20px 16px;
  display: flex;
  flex-direction: row-reverse;
}

.more-dropdown-image{
  right: 0;
  position: absolute;
  margin-right: 20px;
  pointer-events: none;
  width: 30px;
  height: 30px;
  color: #2E70E8;
}

.more-dropdown-text{
  display: flex;
  font-family: 'euclid_regular', sans-serif;
  font-size: 14px;
  color:#1E293B;
  margin-right: 50px;
}
.more-dropdown-item{
  padding-top: 12px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 8px;
  flex-direction: row;
  display: flex;
  gap:30px;
  border-radius: 6px;
  align-items: center;
}

.more-dropdown-item:hover{
  background-color: #F9FAFB;
}

.more-icon-holder{
  color:#1E293B;
  font-size:30px;
  line-height: 20px;
  cursor:pointer;
}

.notification-time{
  color:#475569;
  font-size:12px;
  font-family: 'euclid_medium', sans-serif;
}

.more-holder{
  display: flex;
  right: 0;
  position: absolute;
  flex-direction: column;
  margin-right: 20px;
}

.notification-content-header{
  font-family: 'euclid_regular', sans-serif;
  font-size: 14px;
  height: 20px;
}

.notification-content{
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 12px;
  height: 18px;
}

.notification-holder {
  flex-direction: column;
  align-items: start;
  display: flex;
  gap:8px;
}

.notification-icon-holder{
  border-radius: 50%;
  background-color:#E2E8F0;
  height:48px;
  width: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.notification-container{
  padding:24px;
  border-bottom: 1px solid #E2E8F0 ;
  flex-direction: row;
  display: flex;
  gap:12px;
  padding-bottom: 39px;
}

.notification-container:hover{
  background-color: #EDF3FF;
}

.tab-select{
  align-items: center;
  padding-top: 10px;
  border-bottom: 1px solid #E2E8F0 ;
  flex-direction: row;
  display: flex;

}

.notification-wrapper {
  position: relative;
}

.header-right{
  gap:12px;
}

.notification-dropdown {
  border: 1px solid #E2E8F0;
  position: absolute;
  top: 40px;
  min-width: 450px;
  flex-direction: column;
  gap: 5px;
  z-index: 99999;
  right: 0;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 17px 0px #E2E8F0;

}

.more-dropdown {
  display:flex;
  padding: 2px 6px 2px 6px;
  top: 50px;
  position: absolute;
  z-index: 99999;
  right: 0;
  background-color: #FFFFFF;
  height: max-content;
  box-shadow: 0px 4px 17px 0px #E2E8F0;
  border-bottom: 1px solid #EAECF0;
  flex-direction: column;
  gap: 10px;
  width: max-content;
}

.notification-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 24px;
  border-bottom: 1px solid #E2E8F0;
}

.notification-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 20px;
  font-style: normal;
  line-height: 30px;
}

.close-btn {
  cursor: pointer;
}

.import-toggle-text{
  font-family: euclid_semi_bold, sans-serif;
  margin-right: 15px;
  font-size: 14px;
  color: #2E70E8;
  cursor: pointer;
}

.tab-text{
  color: #64748B;
  font-size: 14px;
  font-family: euclid_semi_bold, sans-serif;
  cursor:pointer;
}

.tab-holder{
  margin-left: 30px;
  flex-direction:row;
  align-items: center;
  justify-content: center;
}

.tab-text:hover, .tab-text.active{
  color:#000000;
}

</style>
