<template>
  <apexchart type="bar" height="300" :options="{
    ...chartOptions,
    xaxis: {
      categories: data.labels,
    },
  }" :series="data.series"></apexchart>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: {
        series: [],
        labels: [],
      },
    },
  },
  data() {
    return {
      series: [
        {
          name: "Data type",
          data: [1380, 1100, 990, 880, 740, 548, 330, 200],
        },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            barHeight: '80%',
            isFunnel: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val;
          },
          dropShadow: {
            enabled: true,
          },
        },
        legend: {
          show: false,
        },
        colors: ['#90a2eb'],
      },
    };
  }
}
</script>
