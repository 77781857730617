<template>
  <div class="phone-input-wrapper">
    <select
        v-model="selectedCountryCode"
        @change="resetPhoneNumber"
        class="form-input-phone"
    >
      <option value="" disabled selected>{{ $t('phone_country_select') }}</option>
      <option
          v-for="country in phoneCodes"
          :key="country.iso"
          :value="country.code"
      >
      {{ country.name }} ({{ country.code }}) 
      </option>
    </select>
    <input
        class="form-input-phone-text"
        :placeholder="$t('enter_phone_number')"
        v-model="phoneNumberInput"
        :disabled="!selectedCountryCode"
        @input="updatePhoneNumber"
        @keydown.backspace="handleBackspace"
    />
  </div>
</template>

<script>
import phoneCodes from '@/assets/phone-codes.json'

export default {
  name: 'PhoneInput',
  props: {
    modelValue: {
      type: String,
      default: '',
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      phoneCodes,
      phoneNumberInput: '',
      selectedCountryCode: '',
      phoneMask: '',
      previousInputLength: 0
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (newValue !== this.computedPhoneNumber) {
          const match = newValue.match(/^(\d+)(.*)$/)
          if (match) {
            const [, code, number] = match
            this.selectedCountryCode = code
            this.phoneNumberInput = number
          }
        }
      }
    },
    selectedCountryCode(newCode) {
      const country = this.phoneCodes.find(c => c.code === newCode)
      if (country) {
        this.phoneMask = country.mask || '###-###-####'
      }
      this.emitValue()
    }
  },
  computed: {
    computedPhoneNumber() {
      if (this.selectedCountryCode && this.phoneNumberInput) {
        return `${this.selectedCountryCode}${this.phoneNumberInput.replace(/\D/g, '')}`
      }
      return ''
    }
  },
  mounted() {
    this.customizeSelectDisplay()
  },
  updated() {
    this.customizeSelectDisplay()
  },
  methods: {
    customizeSelectDisplay() {
      const select = this.$el.querySelector('select')
      if (select && select.selectedIndex > 0) {
        const selectedOption = select.options[select.selectedIndex]
        const countryCode = selectedOption.value
        selectedOption.textContent = countryCode
      }
    },
    handleBackspace(event) {
      if (event.key === 'Backspace' && this.phoneNumberInput) {
        const cursorPosition = event.target.selectionStart
        const value = this.phoneNumberInput

        if (cursorPosition > 0 && /[-\s]/.test(value[cursorPosition - 1])) {
          event.preventDefault()
          const beforeSpecialChar = value.substring(0, cursorPosition - 2)
          const afterSpecialChar = value.substring(cursorPosition)
          this.phoneNumberInput = beforeSpecialChar + afterSpecialChar

          this.$nextTick(() => {
            event.target.setSelectionRange(cursorPosition - 2, cursorPosition - 2)
          })
        }
      }
    },
    updatePhoneNumber() {
      let rawNumber = this.phoneNumberInput.replace(/\D/g, '')

      if (this.phoneMask) {
        this.phoneNumberInput = this.applyMask(this.phoneMask, rawNumber)
      }
      this.emitValue()
    },
    applyMask(mask, input) {
      let masked = ''
      let inputIndex = 0

      for (let i = 0; i < mask.length && inputIndex < input.length; i++) {
        if (mask[i] === '#') {
          masked += input[inputIndex]
          inputIndex++
        } else {
          masked += mask[i]
        }
      }

      return masked
    },
    resetPhoneNumber() {
      this.phoneNumberInput = ''
      this.emitValue()
    },
    emitValue() {
      this.$emit('update:modelValue', this.computedPhoneNumber)
    }
  }
}
</script>

<style scoped>
.phone-input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.form-input-phone {
  width: 40%;
  outline: none;
}

.form-input-phone-text {
  outline: none;
}

.form-input-phone-text:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.form-input-phone option {
  font-family: inherit;
}
</style>
