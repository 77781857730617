<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-step-1">
          <div class="modal-header">
            <span class="modal-header-txt" v-if="!editMode">{{$t('add_a_new_expert')}}</span>
            <span class="modal-header-txt" v-if="editMode">{{$t('edit_expert')}}</span>
          </div>
          <div class="upload-image-main-div">

            <FileDrop :on-file-selected="onFileSelect" :selected-file="expertData.avatarUrl" />

            <div class="upload-image-div">
              <span class="header-span">{{$t('profile_photo')}}</span>
              <div class="upload-image-div">
                <span class="upload-image-txt">{{$t('explanation')}}</span>
                <span class="upload-image-txt">{{$t('recommended_format')}}</span>
              </div>
            </div>

          </div>
          <div class="forms">
            <div class="double-form">
              <div class="form-div">
                <span class="form-title">{{$t('name_expert')}}</span>
                <input type="text" class="form-input" v-model="expertData.name" :placeholder="$t('name_expert')" />
              </div>
              <div class="form-div">
                <span class="form-title">{{$t('surname_person')}}</span>
                <input type="text" class="form-input" v-model="expertData.surname" :placeholder="$t('surname_person')" />
              </div>
            </div>

            <div class="form-div">
              <span class="form-title">{{$t('title_expert')}}</span>
              <input type="text" class="form-input" v-model="expertData.title" :placeholder="$t('title_expert')" />
            </div>

            <div class="double-form">
              <div class="form-div">
                <span class="form-title">{{$t('email_address')}}</span>
                <input type="text" class="form-input" v-model="expertData.email" :placeholder="$t('email_address')" />
              </div>
              <div class="form-div">
                <span class="form-title">{{$t('phone_number_person')}}</span>
                <input type="text" class="form-input" v-model="expertData.phoneNumber" :placeholder="('phone_number_person')" />
              </div>
            </div>

            <div class="double-form">
              <div class="form-div">
                <span class="form-title">{{$t('birthday')}}</span>
                <input type="date" class="form-input" v-model="birthdayDate" :placeholder="('birthday')" />
              </div>

              <div class="form-div">
                <span class="form-title">{{$t('type')}}</span>
                <SelectBox class="w-100" :options="expertTypes" :on-select="handleTypeSelect" :selected-value="expertData.type" :placeholder="$t('type')" />
              </div>
            </div>
          </div>

          <div class="bottom-wrapper">
            <div class="btn-style" @click="onExpertAction">
              <span class="next-txt" v-if="!isLoading && !editMode">{{$t('add_expert')}}</span>
              <span class="next-txt" v-if="!isLoading && editMode">{{$t('save_button')}}</span>
              <VueSpinnerIos v-if="isLoading" size="20" height="100%" color="#fff" />
            </div>
          </div>
          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg" />
          </div>
        </div>
      </div>
      <SuccessModal :is-visible="showSuccessModal" :on-close="onSuccessModalClose" />
    </div>
  </Transition>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import FileDrop from "@/components/FileDrop.vue";
import expertService from "@/service/expertService";
import {
  VueSpinnerIos
} from 'vue3-spinners'
import {handleErrorResponse} from "@/utils/utils";

export default {
  components: {
    FileDrop,
    SuccessModal,
    TextInput,
    SelectBox,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    expertId: {
      type: String,
      default: ""
    },
    editMode: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: {
        name: "",
        surname: "",
        title: "",
        email: "",
        phoneNumber: "",
        birthday: "",
        avatarUrl: "",
        type: "",
      }
    },
    refreshCallback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false,
      isModalVisible: false,
      showSuccessModal: false,
      uploadedFile: null,
      birthdayDate: null,
      expertData: {
        name: "",
        surname: "",
        title: "",
        email: "",
        phoneNumber: "",
        birthday: "",
        avatarUrl: "",
      },
      expertTypes: [
        {
          value: 'expert',
          text: 'Expert'
        },
        {
          value: 'mentor',
          text: 'Mentor'
        },
        {
          value: 'authorized',
          text: 'Authorized'
        },
      ]
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    onSuccessModalClose() {
      this.showSuccessModal = false;
    },
    onFileSelect(file) {
      this.uploadedFile = file;
    },
    onExpertAction() {
      if (this.editMode) {
        this.handleUpdateExpert();
      } else {
        this.handleAddExpert();
      }
    },
    handleAddExpert() {
      this.isLoading = true;

      const formData = new FormData();

      Object.entries(this.expertData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (this.uploadedFile) {
        formData.append('avatar', this.uploadedFile);
      }

      expertService.addExpert(formData).then((response) => {
        this.showSuccessModal = true;
        this.isLoading = false;

        this.refreshCallback();
      }).catch((error) => {
        this.isLoading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },
    handleUpdateExpert() {
      this.isLoading = true;

      const formData = new FormData();

      Object.entries(this.expertData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (this.uploadedFile) {
        formData.append('avatar', this.uploadedFile);
      }

      formData.append('expertId', this.expertId);

      expertService.updateExpert(formData).then((response) => {
        this.isLoading = false;
        this.refreshCallback();

        this.$snackbar.add({
          type: 'success',
          text: 'Expert updated successfully'
        });
      }).catch((error) => {
        this.isLoading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },
    convertYMDToDmy(dateString) {
      const dateObject = new Date(dateString);

      const day = String(dateObject.getDate()).padStart(2, '0');
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const year = dateObject.getFullYear();

      return `${day}.${month}.${year}`;
    },
    convertDate(date) {
      const dateObject = new Date(date);
      const year = dateObject.getUTCFullYear();
      const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
      const day = dateObject.getUTCDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    handleTypeSelect(value) {
      this.expertData.type = value;
    }
  },

  watch: {
    editData: {
      handler() {
        if (this.editMode) {
          this.expertData = {
            name: this.editData.name,
            surname: this.editData.surname,
            title: this.editData.title,
            email: this.editData.email,
            phoneNumber: this.editData.phone,
            birthday: this.editData.birthday,
            avatarUrl: this.editData.avatarUrl,
          }

          this.birthdayDate = this.convertDate(this.editData.birthday);
        }
      },
      deep: true,
    },
    birthdayDate: {
      handler() {
        this.expertData.birthday = this.convertYMDToDmy(this.birthdayDate);
      }
    }
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
.upload-image-main-div{
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 24px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  height: auto;
}
.upload-image-div{
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
}
.header-span{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 16px;
  line-height: 24px;
}
.upload-image-txt{
  color: #667085;
  font-size: 14px;
  line-height: 20px;
}
.forms{
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  align-items: flex-start;
  width: 100%;
}
.double-form{
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
}
.form-div{
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
  z-index: 2;
}

.form-div.half{
  width: calc(50% - 12px);
}

.form-title{
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.btn-style{
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 48px;
  cursor: pointer;
}
.next-txt{
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.bottom-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
  flex: 1,
}

@media (max-width: 678px) {
  .upload-image-main-div {
    flex-direction: column;
    gap: 10px;
  }
}

</style>
