<template>
  <NotificationLayout :on-send-now="showSendModal" :on-action="handleSave">

    <div class="card-wrapper">
      <div class="title-card-border"></div>
      <div class="question-dropdown" v-click-outside="dropdownOutsideClick">
        <div class="dropdown-button" :class="{'active': typeDropdownVisible}" @click="typeDropdownVisible = !typeDropdownVisible">
          <img :src="notifTypes.find(type => type.id === notifData.content?.type).icon" />
          <span>{{notifTypes.find(type => type.id === notifData.content?.type).name}}</span>
          <img src="@/assets/images/arrowdown.svg" class="arrow" />
        </div>

        <div class="dropdown-items" v-if="typeDropdownVisible">
          <template v-for="nType in notifTypes">
          <div class="dropdown-item" v-if="!nType.requireJourney || journeyId" @click="() => selectType(nType.id)">
            <img :src="nType.icon" />
            <span>{{nType.name}}</span>
          </div>
          </template>
        </div>
      </div>

      <input class="title-input mt-3 w-100" :placeholder="this.$t('add_your_label_here')" v-model="notifData.content.label"/>
      <input class="description-input" :placeholder="this.$t('info_of_the_notification')"  v-model="notifData.content.info" />
    </div>

    <Transition name="slide-up">
      <div class="card-wrapper mt-3" v-if="notifData.content?.type === 'video'">
        <div class="upload-row">
          <FileDrop type="mp4" :on-file-selected="handleFile" :selected-file="notifData.content?.uploadedFile?.attachUrl" />
          <FileDrop :on-file-selected="handleCoverFile" :selected-file="notifData.content?.uploadedCover?.attachUrl" />
        </div>
      </div>
    </Transition>

    <Transition name="slide-up">
      <div class="card-wrapper mt-3" v-if="notifData.content?.type === 'audio'">
        <div class="upload-row">
          <FileDrop type="mp3" :on-file-selected="handleFile" :selected-file="notifData.content?.uploadedFile?.attachUrl" />
          <FileDrop :on-file-selected="handleCoverFile" :selected-file="notifData.content?.uploadedCover?.attachUrl" />
        </div>
      </div>
    </Transition>

    <Transition name="slide-up">
      <div class="card-wrapper mt-3" v-if="notifData.content?.type === 'blog'">
        <span class="form-label">{{$t('select_a_blog')}}</span>
        <SelectBox class="select-box" :options="blogList" :on-select="handleBlogSelect" :placeholder="this.$t('select_a_blog')" :selected-value="notifData.content.blogId ? notifData.content.blogId : ''" />
      </div>
    </Transition>

    <Transition name="slide-up">
      <div class="card-wrapper mt-3" v-if="notifData.content?.type === 'documentDownload'">
        <FileDrop :on-file-selected="handleFile" type="allFiles" />
      </div>
    </Transition>

    <Transition name="slide-up">
      <div class="card-wrapper mt-3" v-if="notifData.content?.type === 'documentUpload'">
        <div class="upload-option" v-for="option in notifData.content?.options">
          <div class="form-row">
            <textarea class="form-input mt-3" style="width: 100%" :placeholder="this.$t('label')" v-model="option.label"  v-textarea-autoresize></textarea>
            <textarea class="form-input mt-3" style="width: 100%" :placeholder="this.$t('info')" v-model="option.info"  v-textarea-autoresize></textarea>
          </div>

          <div class="form-row mt-3">
            <multiselect
                v-model="option.accepted"
                :options="uploadTypes"
                track-by="id"
                label="name"
                :multiple="true"
                placeholder="Accepted types"
            ></multiselect>

            <SelectBox :options="testOptions" :on-select="(value) => handleTestSelect(option, value)" :selected-value="option.testId ? option.testId : ''" placeholder="Select a test" />
          </div>

          <div class="form-row mt-3 mb-3">
            <div class="form-row">
              <span class="form-label">{{$t('required')}}</span>
              <Switch v-model="option.required" />
            </div>
            <div class="form-row">
              <span class="form-label">{{$t('max_file_size')}}</span>
              <input type="number" class="form-input-sm" :placeholder="this.$t('max_file_size')" v-model="option.max_file_size"/>
            </div>
            <div class="row-end">
              <img src="@/assets/images/trash.svg" @click="deleteUpload(option)" />
            </div>
          </div>

        </div>

        <div class="card-footer mt-3">
          <button class="primary-button" @click="addUploadOption">{{$t('add')}}</button>
        </div>
      </div>
    </Transition>

    <Transition name="slide-up">
      <div class="card-wrapper mt-3" v-if="notifData.content?.type === 'interview'">
        <input type="datetime-local" class="form-input" v-model="notifData.content.interviewDate" :min="minDateTime" />
      </div>
    </Transition>


    <div class="card-wrapper mt-3">
      <div class="type-title">
        <span class="type-title-txt">{{$t('in_app')}}</span>
        <Switch v-model="inApp" :on-toggle="handleInAppToggle" />
      </div>
      <Transition name="slide-up">
        <div v-if="inApp">
          <textarea class="form-input mt-3 mb-3" style="width: 100%" :placeholder="this.$t('subject')" v-model="notifData.push.subject" v-textarea-autoresize></textarea>
          <quill-editor theme="snow" content-type="html" v-model:content="notifData.push.content"></quill-editor>
        </div>
      </Transition>

    </div>

    <div class="card-wrapper mt-3">
      <div class="type-title">
        <span class="type-title-txt">{{$t('e-mail')}}</span>
        <Switch v-model="email" :on-toggle="handleMailToggle" />
      </div>
      <Transition name="slide-up">
        <div v-if="email">
          <textarea class="form-input mt-3 mb-3" style="width: 100%" :placeholder="this.$t('subject')" v-model="notifData.mail.subject" v-textarea-autoresize></textarea>
          <quill-editor theme="snow" content-type="html" v-model:content="notifData.mail.content"></quill-editor>
        </div>
      </Transition>
    </div>

    <div class="card-wrapper mt-3">
      <div class="type-title">
        <span class="type-title-txt">{{$t('sms')}}</span>
        <Switch v-model="sms" :on-toggle="handleSMSToggle"/>
      </div>
      <Transition name="slide-up">
        <div v-if="sms">
          <textarea class="form-input mt-3" style="width: 100%" :placeholder="this.$t('subject')" v-model="notifData.sms.subject"  v-textarea-autoresize></textarea>
          <textarea class="form-input mt-3" style="width: 100%" :placeholder="this.$t('content')" v-model="notifData.sms.subject"  v-textarea-autoresize></textarea>
        </div>
      </Transition>
    </div>


    <SendNotificationModal :visible="sendModalVisible" :onHide="handleSendModalHide" :journey-id="journeyId" :notif-data="notifData" />
    <LoadingOverlay :is-loading="isLoading" />
  </NotificationLayout>
</template>

<script>

import LoadingOverlay from "@/components/LoadingOverlay.vue";
import NotificationLayout from "@/components/layouts/NotificationLayout.vue";
import notificationContentService from "@/service/notificationContentService";
import SendNotificationModal from "@/components/modals/SendNotificationModal.vue";
import {deepClone, formatDateDMYHI, handleErrorResponse} from "@/utils/utils";
import Switch from "@/components/Switch.vue";
import videoOutline from "@/assets/images/videoOutline.svg";
import audioOutline from "@/assets/images/audioOutline.svg";
import textOutline from "@/assets/images/textOutline.svg";
import downloadOutline from "@/assets/images/downloadOutline.svg";
import uploadOutline from "@/assets/images/uploadOutline.svg";
import blogOutline from "@/assets/images/blogOutline.svg";
import linkOutline from "@/assets/images/linkOutline.svg";
import camOutline from "@/assets/images/camOutline.svg";
import FileDrop from "@/components/FileDrop.vue";
import Multiselect from "vue-multiselect";
import blogService from "@/service/blogService";
import SelectBox from "@/components/SelectBox.vue";
import testService from "@/service/testService";

export default {
  name: 'NotificationDetails',
  components: {
    SelectBox,
    Multiselect,
    FileDrop,
    Switch,
    SendNotificationModal,
    NotificationLayout,
    LoadingOverlay,
  },
  data() {
    return {
      isLoading: true,
      sendNowLoading: false,
      sendModalVisible: false,
      typeDropdownVisible: false,
      editMode: false,
      journeyId: null,
      uploadTypes: [
        {
          id: 'png',
          name: 'PNG'
        },
        {
          id: 'jpg',
          name: 'JPG'
        },
        {
          id: 'pdf',
          name: 'PDF'
        },
      ],
      notifTypes: [
        {
          id: 'video',
          name: this.$t('video_type'),
          icon: videoOutline,
          requireJourney: false,
        },
        {
          id: 'audio',
          name: this.$t('audio_type'),
          icon: audioOutline,
          requireJourney: false,
        },
        {
          id: 'text',
          name: this.$t('text_question'),
          icon: textOutline,
          requireJourney: false,
        },
        {
          id: 'documentDownload',
          name: this.$t('document_download'),
          icon: downloadOutline,
          requireJourney: false,
        },
        {
          id: 'documentUpload',
          name: this.$t('document_upload'),
          icon: uploadOutline,
          requireJourney: false,
        },
        {
          id: 'blog',
          name: this.$t('blog_title'),
          icon: blogOutline,
          requireJourney: false,
        },
        {
          id: 'link',
          name: 'Link',
          icon: linkOutline,
          requireJourney: false,
        },
        {
          id: 'interview',
          name: 'Interview',
          icon: camOutline,
          requireJourney: true,
        }
      ],
      notifData: {
        sendDate: null,
        status: true,
        users: [],
        content: {
          type: 'text',
          label: '',
          info: '',
        },
        sms: {},
        push: {},
        mail: {},
      },
      inApp: true,
      email: false,
      sms: false,
      blogList: [],
      testList: [],
      testOptions: [],
    }
  },

  async mounted() {
    const routeParams = this.$route.params;
    const routeQuery = this.$route.query;

    if (routeQuery.journeyId && routeQuery.journeyId !== '' && routeQuery.journeyId !== 'null') {
      this.journeyId = routeQuery.journeyId;
    }

    await blogService.getBlogList()
        .then(response => {
          this.blogList = response.data.data.map(blog => {
            return {
              value: blog.blogId,
              text: blog.name,
            }
          });
        })
        .catch(err => {
          handleErrorResponse(err, this.$snackbar);
        });

    await testService.getTestList().then((response) => {
      this.testList = response.data.data;
    }).catch(() => {
      this.$snackbar.add({
        text: "Fetching test list failed",
        type: "error",
      });
    });

    if (routeParams.id !== "new") {
      this.isLoading = true;

      notificationContentService.getNotificationDetails(routeParams.id)
          .then(response => {
            this.handleGet(response.data.data);
          })
          .catch(err => {
            handleErrorResponse(err, this.$snackbar);
          })
          .finally(() => {
            this.isLoading = false;
          });
    } else {
      this.isLoading = false;
    }

  },
  watch: {
    testList: {
      handler: function (val) {
        this.testOptions = val.filter(item => item.type === 'test').map(test => {
          return {
            value: test.id,
            text: test.name,
          }
        });
        console.log("testOptions", this.testOptions);
      },
      deep: true,
    }
  },
  methods: {
    handleSendModalHide() {
      this.sendModalVisible = false;
    },
    showSendModal() {
      this.sendModalVisible = true;
    },
    dropdownOutsideClick() {
      this.typeDropdownVisible = false;
    },
    selectType: function (typeID) {
      this.typeDropdownVisible = false;
      if (typeID === 'documentUpload') {
        if (!this.notifData.content.options) {
          this.notifData.content.options = [];
        }
        this.notifData.content.options.push({
          label: '',
          info: '',
          accepted: [],
          required: true,
          max_file_size: 5242880,
        });
      } else if (typeID === 'video' && typeID === 'audio') {
        this.notifData.content.file = {
          attach: null,
        };
        this.notifData.content.coverImage = {
          attach: null,
        };

      } else {
        this.notifData.content.options = [];
      }

      this.notifData.content.type = typeID;

    },
    handleGet(data) {
      const tempData = JSON.parse(JSON.stringify(data));

      this.editMode = true;
      this.inApp = tempData.push !== "";
      this.email = tempData.email !== "";
      this.sms = tempData.sms !== "";

      if (tempData.content?.type === 'documentUpload') {
        try {
          tempData.content.options.map(option => {
            if (!option.accepted) {
              option.accepted = [];
            } else {
              option.accepted = option.accepted.split(',').map(type => {
                return {
                  id: type,
                  name: type.toUpperCase(),
                }
              });
            }

          });
        } catch (e) {
          console.log(e);
        }
      }

      console.log("data", tempData);

      this.notifData = tempData;
    },
    handleFile(file) {
      if (!this.notifData.content.file) {
        this.notifData.content.file = {};
      }

      this.notifData.content.file.attach = file;
      console.log("file",  this.notifData.content.file.attach);
    },
    handleCoverFile(file) {
      if (!this.notifData.content.coverImage) {
        this.notifData.content.coverImage = {};
      }

      this.notifData.content.coverImage.attach = file;
    },
    handleInAppToggle(status) {
      if (!status) {
        this.notifData.push = {};
      } else {
        this.notifData.push = {
          subject: '',
          content: '',
        }
      }
    },
    handleMailToggle(status) {
      if (!status) {
        this.notifData.mail = {};
      } else {
        this.notifData.mail = {
          subject: '',
          content: '',
        }
      }
    },
    handleSMSToggle(status) {
      if (!status) {
        this.notifData.sms = {};
      } else {
        this.notifData.sms = {
          subject: '',
          content: '',
        }
      }
    },
    addUploadOption() {
      if (!this.notifData.content.options) {
        this.notifData.content.options = [];
      }
      this.notifData.content.options.push({
        label: '',
        info: '',
        accepted: [],
        required: true,
        max_file_size: 5242880,
      });
    },
    handleBlogSelect(blogId) {
      this.notifData.content.blogId = blogId;
    },
    handleSave() {
      const saveTemp = deepClone(this.notifData);
      saveTemp.journeyId = this.journeyId;


      if (saveTemp.content.interviewDate && saveTemp.content.interviewDate !== '') {
        try {
          saveTemp.content.interviewDate = formatDateDMYHI(saveTemp.content.interviewDate);
        } catch (e) {
          this.$snackbar.add({
            text: 'Invalid date format.',
            type: 'error'
          });
          return;
        }
      }

      if (saveTemp.content.type === 'documentUpload') {
        saveTemp.content.options.map(option => {
          option.accepted = option.accepted.map(type => type.id).join(',');
        });
      }

      if (this.editMode) {
        this.updateContent(saveTemp);
      } else {
        this.addContent(saveTemp);
      }
    },
    updateContent(saveTemp) {
      saveTemp.isTemplate = true;
      this.isLoading = true;
      notificationContentService.updateNotificationContent(saveTemp)
          .then(response => {
            this.$snackbar.add({
              text: 'Notification updated.',
              type: 'success'
            });
            this.handleGet(response.data.data);
          })
          .catch(err => {
            handleErrorResponse(err, this.$snackbar);
          })
          .finally(() => {
            this.isLoading = false;
          })
    },
    addContent(saveTemp) {
      saveTemp.isTemplate = true;
      this.isLoading = true;
      notificationContentService.addNotificationContent(saveTemp)
          .then(res => {
            this.$snackbar.add({
              text: 'Notification added.',
              type: 'success'
            });
            window.location.href = "/notification/" + res.data.data.notificationId;
          })
          .catch(err => {
            handleErrorResponse(err, this.$snackbar);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    deleteUpload(option) {
      if (this.notifData.content.options) {
        if (this.notifData.content.options.length === 1) {
          this.$snackbar.add({
            text: 'At least one upload option is required.',
            type: 'warning'
          });
          return;
        }
        this.notifData.content.options = this.notifData.content.options.filter(opt => opt !== option);
      }
    },
    handleTestSelect(option, testId) {
      option.testId = testId;
    }
  },
  computed: {
    minDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    }
  }
}
</script>

<style scoped>
.card-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 20px 20px 20px 28px;
  display: flex;
  flex-direction: column;
}

.title-card-border {
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0;
  left: 0;
  background-color: #1C3CD5;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.title-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  background-color: transparent;
  color: #040C2D;
  margin-bottom: 15px;
}

.description-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  background-color: transparent;
  color: #98A2B3;
}

.title-input:focus, .description-input:focus {
  border-bottom: 1px solid #D0D5DD;
}


.question-dropdown {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.question-dropdown img {
  height: 16px;
  width: 16px;
}

.question-dropdown span {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 12px;
}

.dropdown-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
}

.dropdown-button:hover, .dropdown-button.active {
  background-color: #F2F4F7;
}

.dropdown-button .arrow {
  transition: transform 0.1s linear;
}

.dropdown-button.active .arrow {
  transform: rotate(180deg);
}

.dropdown-items {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 99;
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
}

.dropdown-item:hover {
  background-color: #F5F7FA;
}

.type-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}

.type-title-txt {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #040C2D;
}

.upload-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.upload-option {
  border-bottom: 1px solid rgba(102, 112, 133, 0.2);
}

.upload-option:last-child {
  border-bottom: none;
}

.card-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.row-end {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
</style>
