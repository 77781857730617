<template>
  <Layout>
    <h3 class="page-title">{{ $t('total_therapy') }}</h3>
    <div class="filter-wrapper">
      <TherapyFilters />

      <div class="justify-end-div">
        <ExpandableSearch />
        <router-link to="/therapy/new">
          <div class="justify-end-add-btn no-text-select">
            <img src="../assets/images/plus.svg" />
            <span class="justify-end-addbtn-txt">{{$t('add_new')}}</span>
          </div>
        </router-link>
      </div>
    </div>

    <div class="therapy-list-grid">
        <TherapyCard
            v-for="item in therapyData.data"
            :is-loading="isLoading"
            :image="item.avatar"
            :title="item.name"
            :description="item.shortDescription"
            :remaining-days="item.remainingDay"
            :assigned-at="item.assignedAt"
            :tags="item.tags.map((x) => x.name)"
            :to="`/therapy/${item.therapyId}`"
            :remaining="item.remaining"
            :start-at="item.startAt"
            :finish-at="item.finishAt"
            :is-publish="item.isPublish"
            :companies-assigned-count="item.companiesAssignedCount"
            :user-assigned-count="item.userAssignedCount"

        />

      <TherapyCard v-if="isLoading" />
      <TherapyCard v-if="isLoading" />
      <TherapyCard v-if="isLoading" />
    </div>


    <div class="pagination-align-center">
      <Pagination :current-page="1" :total-items="therapyData.total" :per-page="20" :on-page-change="handlePage"/>
    </div>
  </Layout>
</template>

<script>
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import Layout from "@/components/layouts/Layout.vue";
import TherapyCard from "@/components/TherapyCard.vue";
import therapyService from "@/service/therapyService";
import Pagination from "@/components/Pagination.vue";
import TherapyFilters from "@/components/tables/Therapy/TherapyFilters.vue";

export default {
  components: {
    TherapyFilters,
    Pagination,
    TherapyCard,
    Layout,
    ExpandableSearch
  },
  data() {
    return {
      isLoading: true,
      therapyData: {},
      currentPage: 1
    }
  },
  watch: {
    $route() {
      this.handlePage(1);
    }
  },
  methods: {
    handlePage(page) {
      if (this.isLoading && this.currentPage !== 1) return;
      this.isLoading = true;
      this.currentPage = page;

      const routeQuery = this.$route.query;
      const tempFilters = [];

      if (routeQuery.searchText) {
        tempFilters.push({
          key: 'searchText',
          value: routeQuery.searchText
        });
      }

      if (routeQuery.startAt) {
        tempFilters.push({
          key: 'startAt',
          value: routeQuery.startAt
        });
      }

      if (routeQuery.startAtEnd) {
        tempFilters.push({
          key: 'startAtEnd',
          value: routeQuery.startAtEnd
        });
      }

      if (routeQuery.finishAt) {
        tempFilters.push({
          key: 'finishAt',
          value: routeQuery.finishAt
        });
      }

      if (routeQuery.finishAtEnd) {
        tempFilters.push({
          key: 'finishAtEnd',
          value: routeQuery.finishAtEnd
        });
      }

      if (routeQuery.isPublish) {
        if (routeQuery.isPublish === "1") {
          tempFilters.push({
            key: 'isPublish',
            value: "1"

          })
        } else if (routeQuery.isPublish === "0") {
          tempFilters.push({
            key: 'isPublish',
            value: "0"
          })
        }
      }

      therapyService.getTherapies(page, tempFilters).then(res => {
        this.therapyData = res.data.data;
        this.isLoading = false;
      }).catch(err => {
        this.$snackbar.add({
          text: err.response.data.message,
          type: 'danger'
        });
      });
    }
  }

}
</script>

<style scoped>
.page-title {
  font-family: euclid_medium, sans-serif;
  font-size: 20px;
  color: #040C2D;
  margin-bottom: 20px;
}
.filter-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.filter-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.filter-dropdown {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}
.dropdown-text {
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
}

.therapy-list-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media (max-width: 1024px) {
  .therapy-list-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .therapy-list-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
