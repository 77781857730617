import axios from '../axios';

const testService = {
    getTests(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/test/store?limit=20&page=${page}${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/test/store?limit=20&page=${page}`);
        }
    },
    getAllTests() {
        return axios.get('/v1/admin/test/store?list=true');

    },

    getCopy(testId) {
        return axios.post('/v1/admin/test/copy', {
            testId: testId
        });
    },

    addTest(data) {
        data = this.convertBooleanValuesToNumbers(data);

        return axios.post('/v1/admin/test/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateTest(data) {
        data = this.convertBooleanValuesToNumbers(data);

        return axios.post('/v1/admin/test/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    getTestDetail(id) {
        return axios.get(`/v1/admin/test/getFromId/${id}`);
    },
    getTestList() {
        return axios.get('/v1/admin/test/list');
    },
    getQuestion(id, type = 'test') {
        return axios.get(`/v1/admin/test/questionsList/${id}?type=${type}`);
    },
    assignTest(data) {
        return axios.post('/v1/admin/test/assign', data);
    },
    convertBooleanValuesToNumbers(data) {
        const convertValue = (value) => {
            if (typeof value === 'boolean') {
                return value ? 1 : 0;
            }
            if (typeof value === 'object') {
                for (const key in value) {
                    if (value.hasOwnProperty(key)) {
                        value[key] = convertValue(value[key]);
                    }
                }
            }
            return value;
        };

        return convertValue(data);
    },
    sendReport(journeyId,testId) {
        let data = {
            journeyId: journeyId,
            testId: testId,
        };
        return axios.post(`/v1/admin/journey/test/notification`,data);
    }
}


export default testService;
