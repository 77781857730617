<script setup>
import { Handle, Position } from '@vue-flow/core'
import {truncate} from "@/utils/utils";
import {useI18n} from "vue-i18n";

const props = defineProps(['id', 'data']);
const { t } = useI18n();

const getType = (type) => {
  if (type === 'multiple') {
    return t('multiple_choice');
  } else if (type === 'single') {
    return t('single_choice');
  } else  if (type === 'yesNo') {
    return t('yes_no_question');
  }  else if (type === 'number') {
    return t('number_question');
  } else if (type === 'text') {
    return t('text_question');
  } else if (type === 'grading') {
    return t('grading_question');
  } else if (type === 'rating') {
    return t('rating_question')
  } else if (type === 'dragAndDrop') {
    return t('drag_drop_question');
  } else {
    return "Undefined type";
  }
};
</script>

<template>
  <div class="question-node">
    <Handle id="input" type="target" :position="Position.Top"/>

    <div class="node-header">
      <img src="../../../assets/images/questionOutlineDark.svg" alt="icon"/>
      <span class="node-type">{{$t('question_type_program')}}</span>
    </div>

    <div class="node-body">
      <div class="node-body-title">
        <div class="begin-text-line"></div>
        <span class="question-type">{{ getType(props.data.content?.type) }}</span>
      </div>

      <span class="question-name">{{props.data?.name ? truncate(props.data?.name, 50) : 'No name'}}</span>
    </div>

    <div class="handles">
      <div class="handle-row" v-for="(option, index) in props.data.content?.options">
        <span>{{option.name}}</span>
        <Handle :id="String(index)" type="source" :position="Position.Right" class="handle"/>
      </div>
      <div class="handle-row" v-if="props.data.content?.settings?.other">
        <span>Other</span>
        <Handle id="-2" type="source" :position="Position.Right" class="handle"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.question-node {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 300px;
  min-width: 180px;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #4A68F8;
  background-color: #fff;
  border-radius: 6px;
}

.node-header {
  width: 100%;
  background-color: #f1f1f1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 10px;
}

.node-header img {
  width: 24px;
  height: 24px;
}

.node-type {
  font-size: 16px;
  color: #101828;
  font-family: 'euclid_regular', sans-serif;
}

.node-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.node-body-title {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.begin-text-line {
  width: 3px;
  height: 20px;
  background-color: #4A68F8;
  border-radius: 6px;
}

.question-name {
  font-size: 14px;
  color: #101828;
  font-family: 'euclid_regular', sans-serif;
}

.question-type {
  font-size: 14px;
  color: #667085;
  font-family: 'euclid_regular', sans-serif;
}

.handles {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.handle-row {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 10px;
  min-width: 140px;
  border-top: 1px solid #4A68F8;
}

.handle-row span {
  font-size: 14px;
  color: #101828;
}

.handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -6px;
}
</style>
