<template>
  <div class="filter-view">
    <JourneyUserFilters />
    <div class="justify-end-div" v-if="rightAction">
      <div class="justify-end-search">
        <ExpandableSearch :searchText="searchText" :on-change="handleSearch" />
      </div>
    </div>
  </div>

  <div class="table-container">
    <table class="content-table">
      <thead class="header-table">
      <tr class="trhead">
        <th><input type="checkbox" :checked="selectAll"></th>
        <th>{{$t('e_mail')}}</th>
        <th>{{$t('status')}}</th>
        <th>{{$t('full_name')}}</th>
        <th>Program</th>
        <th>Modül</th>
        <th>{{$t('last_step')}}</th>
        <th>{{$t('on_schedule')}}</th>
        <th>{{$t('actions_journey')}}</th>
      </tr>
      </thead>
      <tbody class="tbody-container" v-click-outside="tableOutsideClick">
      <tr class="tr-class" v-if="!loading" v-for="user in users" :key="user.user?.userId">
        <td class="td-style"><input type="checkbox" @change="(e) => toggleJourney(e, user.journeyId)" :checked="selectedJourneyIds.includes(user.journeyId)"></td>
        <td class="td-style" >
          <div class="name-row" @click="activeGoDropdownId !== user.journeyId ? activeGoDropdownId = user.journeyId : activeGoDropdownId = -1">
            <img class="company-icon" :src="user.avatarUrl" v-if="user.avatarUrl"/>
            <span class="test-class">{{ user.email}} </span>
          </div>
          <div class="dropdown-body" v-if="activeGoDropdownId === user.journeyId">
            <router-link class="dropdown-option" :to="'/users/detail/' + user.user?.userId">
              {{$t('profile')}}
            </router-link>

            <router-link class="dropdown-option" :to="'/journeys/' + user.journeyId">
              {{$t('journey')}}
            </router-link>
          </div>
        </td>

        <td class="td-style">
          <span class="active-tag" v-if="!user.status">{{$t('active')}}</span>
          <span class="ended-tag" v-else>{{$t('ended')}}</span>
        </td>

        <td class="td-style">
          <span class="test-class">
            {{ user.user?.fullName}}
          </span>
        </td>

        <td class="td-style">
          <span class="test-class">
            {{ user.program }}
          </span>
        </td>

        <td class="td-style">
          <span class="test-class">
            {{ user?.moduleName }}
          </span>
        </td>

        <td class="td-style"><span class="test-class">
          {{ user.lastStep ? truncate(user.lastStep, 50) : '' }}
        </span></td>

        <td class="td-style"> <span class="test-class">
          {{ user.createdAt }}
        </span></td>
        <td>
          <div class="action-wrapper">
            <ActionDropdown :actions="userActions" :placeholder="$t('actions')"/>
          </div>
        </td>
      </tr>
      <tr v-else>
        <td colspan="8" class="table-loading">
          <VueSpinner size="16" height="100%" color="#8c8c8c" />
          <span class="text">{{$t('loading')}}</span>
        </td>
      </tr>
      <tr v-if="!loading && users.length === 0 ">
        <td colspan="8" class="table-loading">
          <span class="text">{{$t('no_data_found')}}</span>
        </td>
      </tr>
      </tbody>
    </table>

  </div>


  <div class="bottom-actions" v-if="selectedJourneyIds.length > 0">
    <span class="selected-label">Selected {{selectedJourneyIds.length}} journey</span>
    <ActionDropdown :actions="userActions" :on-action="handleMultiAction" :placeholder="$t('actions')"/>
  </div>
</template>

<script>
import AddUserModal from '@/components/modals/InviteUserModal.vue';
import FilterModal from '@/components/tables/Company/CompanyFilterModal.vue';
import CompanyFieldFilter from '@/components/table-filters/CompanyFieldFilter.vue';
import TherapyFilter from "@/components/table-filters/NumberFilter.vue";
import UserAmountFilter from "@/components/table-filters/MinMaxNumberFilter.vue";
import PaymentVolume from "@/components/table-filters/PaymentVolume.vue";
import DateFilter from "@/components/table-filters/DateFilter.vue";
import therapyNumberFilter from "../../table-filters/NumberFilter.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import {VueSpinner} from "vue3-spinners";
import ActionDropdown from "@/components/ActionDropdown.vue";
import journeyService from "@/service/journeyService";
import {handleErrorResponse, truncate} from "@/utils/utils";
import JourneyUserFilters from "@/components/tables/Journey/JourneyUserFilters.vue";

export default {
  components: {
    JourneyUserFilters,
    ActionDropdown,
    DateFilter,
    PaymentVolume,
    UserAmountFilter,
    AddUserModal,
    FilterModal,
    CompanyFieldFilter,
    TherapyFilter,
    ExpandableSearch,
    VueSpinner
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    addNewUser: {
      type: Function,
      default: () => {}
    },
    users: {
      type: Array,
      required: true
    },
    rightAction: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showModal: false,
      selectAll:false,
      showFilter:false,
      searchText: '',
      activeGoDropdownId: -1,
      activeActionDropdownId: -1,
      multiActionVisible: false,
      selectedJourneyIds: [],
      userActions: [
        {
          name: this.$t('send_notifiaction'),
          action: 'sendNotification'
        },
        {
          name: this.$t('end_the_journey'),
          action: 'endJourney'
        },
        {
          name: this.$t('send_offer'),
          action: 'sendOffer'
        },
        {
          name: this.$t('hired'),
          action: 'offerAccepted'
        },

      ]
    }
  },

  methods: {
    truncate,
    handleSearch(event) {
      this.searchText = event.target.value;
    },
    toggleJourney(e, journeyId) {
      if (e.target.checked) {
        this.selectedJourneyIds.push(journeyId);
      } else {
        this.selectedJourneyIds = this.selectedJourneyIds.filter(id => id !== journeyId);
      }
    },
    tableOutsideClick() {
      this.activeGoDropdownId = -1;
    },
    handleMultiAction(action) {
      if (action === 'endJourney' && this.selectedJourneyIds.length > 0) {
        journeyService.endJourney(this.selectedJourneyIds)
            .then(() => {
              this.selectedJourneyIds = [];
              this.$snackbar.add({
                text: 'Selected journeys ended',
                type: 'success'
              });
            })
            .catch(err => {
              handleErrorResponse(err, this.$snackbar);
            });
      } else if (action === 'sendNotification' && this.selectedJourneyIds.length > 0) {
        this.multiActionVisible = true;
        const userIds = this.selectedJourneyIds.map(id => {
          return this.users.find(user => user.journeyId === id).user.userId;
        });

        window.location.href = `/notification/new?userIds=${userIds.join(',')}`;
      } else if (action === 'sendOffer' && this.selectedJourneyIds.length > 0) {
        console.log(this.selectedJourneyIds, action)
      } else if (action === 'offerAccepted' && this.selectedJourneyIds.length > 0){}
        console.log(this.selectedJourneyIds, action)
      }

    },

}
</script>

<style>
.table-container {
  width: 100%;
  height: 100%;
  overflow: visible !important;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: left;
  padding: 8px;
}
.trhead{
  background-color: transparent;
}
.tr-class{
  border-bottom-width: 1px;
  border-bottom-color: #F2F4F7;
}

tr td {
  padding-bottom: 12px;
  padding-top: 12px;
  align-items: center;
}
.table-container {
  width: 100%;
}
tr th{
  align-items: center;
  font-size: 12px;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.header-table,
.content-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.header-table {
  background-color: #F9FAFB;
  border-radius: 8px;
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #F2F4F7;
}

.tbody-container {
  overflow-y: auto;
}
.tr-class td tr {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.test-class{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
}
.flex-row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
}
.row-item{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}

.row-item.filter {
  background-color: transparent;
}

.row-item-txt{
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
}

.filter-view{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  min-height: 52px;
}
.justify-end-div{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.justify-end-search{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
}
.justify-end-add-btn{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 8px;
  background-color: #040C2D;
  cursor: pointer;
}
.justify-end-addbtn-txt{
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #FFF;
}

.company-icon {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.show-filters{
  font-family: euclid_medium, sans-serif;
  color: #4A68F8;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
}

.row-item-icon {
  height: 16px;
  width: 16px;
}

.name-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
}

.name-row span {
  font-family: euclid_medium, sans-serif;
  color: #000;
}

.name-row:hover.name-row span{
  color: #4A68F8;
}

.td-style {
  position: relative;
}

.dropdown-body {
  position: absolute;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 8px;
  top: 40px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 999999;
}

.dropdown-option {
  cursor: pointer;
  font-size: 12px;
  padding: 7px 15px;
  display: block;
  border-radius: 8px;
}

.dropdown-option:hover {
  background: #ffffff;
}

.action-wrapper {
  position: relative;
}

.go-dropdown-button {
  cursor: pointer;
  font-size: 12px;
  padding: 7px 15px;
  border-radius: 8px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.action-dropdown {
  position: absolute;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 8px;
  top: 40px;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  gap: 5px;

}

.action-dropdown.right {
  right: 0;
}

.action-list {
  cursor: pointer;
  font-size: 12px;
  padding: 7px 15px;
  display: block;
  border-radius: 8px;
}

.action-list:hover {
  background: #ffffff;

}

.dropdown-arrow {
  transition: transform 0.3s;
}

.arrow-up {
  transform: rotate(180deg);
}

.bottom-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.selected-label {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  line-height: 16px;
}

.active-tag {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #4A68F8;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  line-height: 16px;
}

.ended-tag {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #FF0000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  line-height: 16px;
}
</style>
