import axios from '../axios';
import {convertBooleanValuesToNumbers} from "@/utils/utils";

const sliderService = {
    getSliders(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/slider/store?page=${page}&limit=20${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/slider/store?page=${page}&limit=20`);
        }
    },
    getSliderDetail(id) {
        return axios.get(`/v1/admin/slider/getFromId/${id}`);
    },
    addSlider(data) {
        data = convertBooleanValuesToNumbers(data);
        return axios.post(`/v1/admin/slider/add`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateSlider(data) {
        data = convertBooleanValuesToNumbers(data);
        return axios.post(`/v1/admin/slider/update`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteSlider(id) {
        return axios.delete(`/v1/admin/slider/delete/${id}`);
    }
}

export default sliderService;
